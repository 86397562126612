import actions from './actions';
import clone from "clone";
import TimeRangePicker from "../../components/filters/dateRangePicker";
import {
    ALL_SOLUTIONS,
    DIMENSIONS,
    metricBySolution,
    SAMPLE_INTERVAL_DEFAULT,
    SAMPLE_INTERVAL_START_DAY_DEFAULT,
    SAMPLE_INTERVAL_HOUR_DEFAULT,
    TOTAL_COLUMN
} from '../../containers/dashboard/config';
import {ReportPropertiesQueryParameters, sortOrAddFromSortedArray} from "../../../helpers/utility";
import {uniq, concat} from 'lodash'

export const createNewSavedReport = (userEmail, isUsage) => ({
    name: '',
    description: '',
    isAlertEnabled: isUsage,
    alertReminderDays: isUsage ? 0 : 2,
    relativeTimeRange: 'Yesterday',
    sampleIntervalPeriod: SAMPLE_INTERVAL_DEFAULT,
    sampleIntervalStartDay: SAMPLE_INTERVAL_START_DAY_DEFAULT,
    sampleIntervalHour: SAMPLE_INTERVAL_HOUR_DEFAULT,
    alertMailingList: [userEmail],
    isResolvedEnabled: true
});

const dimensionsKeys = DIMENSIONS.filter(dimension => dimension.isDefault).map(dimension => dimension.key);
const reportsPropertiesQueryParameters = new ReportPropertiesQueryParameters();
const {filteredSolutions, filters} = reportsPropertiesQueryParameters.getFiltersAndSolutions();
let solutions = localStorage.getItem('solutions') ?
    [...new Set(localStorage.getItem('solutions').split(',').concat(filteredSolutions))]
        .filter(solution => ALL_SOLUTIONS.indexOf(solution) >= 0) : ALL_SOLUTIONS;
solutions = sortOrAddFromSortedArray(solutions, ALL_SOLUTIONS);
let parametersMappingColumns = localStorage.getItem('parametersMappingColumns')
const initState = {
    reportProperties: {
        pid: {
            pid: '',
            name: '',
            enabled_features: ALL_SOLUTIONS.join(',')
        },
        timeRange: {
            from: reportsPropertiesQueryParameters.getFromDateTime(TimeRangePicker.defaultProps.fromDateTime),
            to: reportsPropertiesQueryParameters.getToDateTime(TimeRangePicker.defaultProps.toDateTime)
        },
        timeZone: reportsPropertiesQueryParameters.getTimeZone(TimeRangePicker.defaultProps.timeZone),
        dimensions: reportsPropertiesQueryParameters.getDimensions(dimensionsKeys),
        selectedMetrics: reportsPropertiesQueryParameters.getMetrics([]),
        timeDimensions: reportsPropertiesQueryParameters.getTimeDimensions([]),
        conditions: reportsPropertiesQueryParameters.getConditions(dimensionsKeys, []),
        filteredSolutions: filteredSolutions,
        filters: filters,
        parametersMappingColumns: parametersMappingColumns
    },
    solutions: solutions,
    data: {
        schema: dimensionsKeys,
        rows: []
    },
    graphs: {
        schema: dimensionsKeys,
        rows: [],
        total: 0,
        totalBySolution: {},
        dataByCategory: {}
    },
    insights: [],
    highRatesMessages: {},
    closedHighRatesMessages: [],
    reportId: '',
    savedReportProperties: undefined,
    exportLoading: false,
    exportUrl: '',
    exportRowsUpdated: 0,
    exportTotalRows: 0,
    loading: false,
    insightsLoading: false,
    isOpenSupportTicketLoading: false,
    editMode: false,
    error: false,
    smartAgentData: [],
    smartAgentLoading: false,
    smartAgentTabActive: false,
    tableSearchText: [''],
    showIABSubcategories: true,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.FETCH_REPORT:
            let reportProperties = clone(initState.reportProperties);
            reportProperties.pid = state.reportProperties.pid;
            Object.assign(reportProperties, action.reportProperties);
            let newSolutions = [...state.solutions];
            if (reportProperties.pid) {
                let newFilters = reportProperties.filters.filter(filter => filter.field === TOTAL_COLUMN.key)
                reportProperties.filteredSolutions = [];
                if (reportProperties.pid.enabled_features && reportProperties.filters.length) {
                    reportProperties.pid.enabled_features.split(',')
                        .forEach(solution => {
                            if (metricBySolution[solution]) {
                                metricBySolution[solution].forEach(metricGroup =>
                                    metricGroup.masterMetrics
                                        .concat(metricGroup.metricsByCategories).forEach(metric => {
                                            reportProperties.filters.forEach(filter => {
                                                if (filter.field === metric.key) {
                                                    newFilters.push(filter)
                                                    if (reportProperties.filteredSolutions.indexOf(solution) < 0) {
                                                        reportProperties.filteredSolutions.push(solution)
                                                    }
                                                }
                                            })
                                        }
                                    )
                                )
                            }
                        }
                    )
                }
                newSolutions = reportProperties.pid.enabled_features.split(',')
                    .filter(enabledSolution => newSolutions.indexOf(enabledSolution) >= 0 || reportProperties.filteredSolutions.indexOf(enabledSolution) >= 0);
                reportProperties.filters = newFilters;
            }
            newSolutions = sortOrAddFromSortedArray(newSolutions, ALL_SOLUTIONS);
            return {
                ...state,
                loading: true,
                reportProperties: reportProperties,
                solutions: newSolutions,
            };
        case actions.REPORT_SUCCESS_RESULT:
            let rows = action.data.rows && action.data.rows.map(
                row => ({...row,
                    mrc_viewable_rate: row['mrc_viewable_impressions'],
                    mrc_sivt_viewable_rate: row['mrc_sivt_viewable_impressions']})
            );
            return {
                ...state,
                loading: false,
                error: action.error || false,
                data: {
                    schema: action.data.schema || initState.data.schema,
                    rows: rows || initState.data.rows
                },
                graphs: {
                    schema: action.graphs.schema || initState.graphs.schema,
                    rows: action.graphs.rows || initState.graphs.rows,
                    total: action.graphs.total || initState.graphs.total,
                    totalBySolution: action.graphs.totalBySolution || initState.graphs.totalBySolution,
                    dataByCategory: action.graphs.dataByCategory || initState.graphs.dataByCategory
                },
                reportId: action.reportId,
            };
        case actions.REPORT_ERROR_RESULT:
            return {
                ...state,
                loading: false,
                error: state.error,
                rows: []
            };
        case actions.FETCH_HIGH_RATES_MESSAGES:
            return {
                ...state,
                highRatesMessages: {},
                closedHighRatesMessages: []
            };
        case actions.FETCH_HIGH_RATES_MESSAGES_SUCCESS_RESULT:
            return {
                ...state,
                highRatesMessages: action.highRatesMessages,
                closedHighRatesMessages: []
            };
        case actions.FETCH_HIGH_RATES_MESSAGES_ERROR_RESULT:
            return {
                ...state,
                highRatesMessages: {},
                closedHighRatesMessages: []
            };
        case actions.FETCH_HIGH_RATES_MESSAGES_CLOSE:
            let newClosedHighRatesMessages = state.closedHighRatesMessages;
            newClosedHighRatesMessages.push(action.key);
            return {
                ...state,
                closedHighRatesMessages: newClosedHighRatesMessages
            };
        case actions.FETCH_REPORT_INSIGHTS:
            return {
                ...state,
                insightsLoading: true,
            };
        case actions.REPORT_INSIGHTS_SUCCESS_RESULT:
            return {
                ...state,
                insightsLoading: false,
                insights: action.insights,
            };
        case actions.REPORT_INSIGHTS_ERROR_RESULT:
            return {
                ...state,
                insightsLoading: false,
                insights: []
            };
        case actions.CHANGE_REPORT_SOLUTIONS:
            return {
                ...state,
                solutions: action.solutions
            };
        case actions.ADD_METRICS_TO_REPORT:
            let reportProps = clone(state.reportProperties);
            reportProps.selectedMetrics = uniq(concat(reportProps.selectedMetrics, action.metrics));
            return {
                ...state,
                reportProperties: reportProps
            };
        case actions.FETCH_SAVED_REPORT:
            return {
                ...state,
                savedReportProperties: {
                    id: action.savedReportProperties.id,
                    name: action.savedReportProperties.name,
                    isAlertEnabled: action.savedReportProperties.isAlertEnabled,
                    isAlertCSVEnabled: action.savedReportProperties.isAlertCSVEnabled,
                    description: action.savedReportProperties.description,
                    relativeTimeRange: action.savedReportProperties.relativeTimeRange,
                    timeZone: action.savedReportProperties.timeZone,
                    sampleIntervalPeriod: action.savedReportProperties.sampleIntervalPeriod,
                    sampleIntervalStartDay: action.savedReportProperties.sampleIntervalStartDay,
                    sampleIntervalHour: action.savedReportProperties.sampleIntervalHour,
                    alertReminderDays: action.savedReportProperties.alertReminderDays,
                    alertMailingList: action.savedReportProperties.alertMailingList,
                    isResolvedEnabled: action.savedReportProperties.isResolvedEnabled,
                    parametersMappingColumns: action.savedReportProperties.parametersMappingColumns
                },
                editMode: false
            };
        case actions.CHANGE_REPORT_EDIT_MODE:
            let savedReportProperties = state.savedReportProperties;
            if (!savedReportProperties) {
                savedReportProperties = createNewSavedReport(action.userEmail)
            } else if (state.editMode && !savedReportProperties.id) {
                savedReportProperties= undefined;
            }
            return {
                ...state,
                savedReportProperties: savedReportProperties,
                editMode: !state.editMode
            };
        case actions.UPDATE_SAVED_REPORT:
            return {
                ...state,
                loading: true
            };
        case actions.UPDATE_SAVED_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                editMode: false
            };
        case actions.UPDATE_SAVED_REPORT_ERROR:
            return {
                ...state,
                loading: false,
            };
        case actions.REMOVE_SAVED_REPORT:
            return {
                ...state,
                loading: true
            };
        case actions.REMOVE_SAVED_REPORT_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case actions.REMOVE_SAVED_REPORT_ERROR:
            return {
                ...state,
                loading: false
            };
        case actions.CLEAR_SAVED_REPORT:
            return {
                ...state,
                editMode: false,
                savedReportProperties: undefined
            };
        case actions.EXPORT_REPORT:
            return {
                ...state,
                exportLoading: true,
                error: false,
                exportUrl: ''
            };
        case actions.EXPORT_REPORT_SUCCESS_RESULT:
            return {
                ...state,
                exportLoading: false,
                error: false,
                exportUrl: action.url,
                exportRowsUpdated: action.rows_updated,
                exportTotalRows: action.total_rows,
            };
        case actions.EXPORT_REPORT_ERROR_RESULT:
            return {
                ...state,
                exportLoading: false,
                error: false,
                exportUrl: '',
                exportRowsUpdated: 0,
                exportTotalRows: 0,
            };
        case actions.REPORT_EXPORT_UPDATE_PROGRESS:
            return {
                ...state,
                exportLoading: true,
                error: false,
                exportUrl: '',
                exportRowsUpdated: action.reportExportStatus.rows_updated,
                exportTotalRows: action.reportExportStatus.total_rows,
            };
        case actions.OPEN_SUPPORT_TICKET:
            return {
                ...state,
                isOpenSupportTicketLoading: true
            };
        case actions.OPEN_SUPPORT_TICKET_DONE:
            return {
                ...state,
                isOpenSupportTicketLoading: false
            };
        case actions.FETCH_SMART_AGENT_DATA:
            return {
                ...state,
                smartAgentData: [],
                smartAgentLoading: true
            }
        case actions.SMART_AGENT_DATA_SUCCESS_RESULT:
            return {
                ...state,
                smartAgentData: action.smartAgentData,
                smartAgentLoading: false
            }
        case actions.SMART_AGENT_DATA_ERROR_RESULT:
            return {
                ...state,
                smartAgentData: [],
                smartAgentLoading: false
            }
        case actions.SET_TABLE_SEARCH_TEXT:
            return {
                ...state,
                tableSearchText: [action.searchText]
            }
        case actions.SET_SMART_AGENT_TAB_ACTIVE:
            return {
                ...state,
                smartAgentTabActive: action.smartAgentTabIsActive
            }
        case actions.SET_IVT_TAB_ACTIVE:
            return {
                ...state,
                isIVTTabActive: action.isIVTTabActive
            }
        case actions.TOGGLE_SET_SHOW_IAB_SUBCATEGORIES:
            return {
                ...state,
                showIABSubcategories: !state.showIABSubcategories
            }
        default:
            return state;
    }
}
