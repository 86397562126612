import React, {Component} from "react";
import {connect} from "react-redux";
import clone from "clone";
import {Link} from "react-router-dom";
import {Icon, Layout} from "antd";
import Badge from '../../../containers/Uielements/Badge/badge.style';
import options from "../../sidebar";
import Tooltip from '../../../components/uielements/tooltip';
import Scrollbars from "../../../components/utility/customScrollBar.js";
import Menu from "../../../components/uielements/menu";
import IntlMessages from "../../../components/utility/intlMessages";
import DashboardSidebarWrapper from "./sidebar.style";
import appActions from "../../../redux/app/actions";
import Logo from "../../../components/utility/logo";
import SolutionsMenu from "./solutionsMenu"
import {MaterialRoundedMail, ReportCreationSVG, ReportSVG} from "../../components/icons/icons";
import ReportsActions from "../../redux/reports/actions";
import theme from "../../../settings/themes/protectedmediatheme";
import basicStyle from "../../../settings/basicStyle";
import ReadOnlyReportProperties from "../dashboard/reportProperties/readOnlyReportProperties";
import {fireEventToGoogleAnalytics} from "../../../helpers/utility";

const {savedReportFetch, reportFetch, savedReportClear, changeReportEditMode} = ReportsActions;

const SubMenu = Menu.SubMenu;
const {Sider} = Layout;

const {
    toggleOpenDrawer,
    changeOpenKeys,
    changeCurrent,
    toggleCollapsed
} = appActions;

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.onOpenChange = this.onOpenChange.bind(this);
    }

    handleClick(e) {
        this.props.changeCurrent([e.key]);
        if (this.props.app.view === "MobileView") {
            setTimeout(() => {
                this.props.toggleCollapsed();
                this.props.toggleOpenDrawer();
            }, 100);
        }
    }

    onOpenChange(newOpenKeys) {
        const {app, changeOpenKeys} = this.props;
        const latestOpenKey = newOpenKeys.find(
            key => !(app.openKeys.indexOf(key) > -1)
        );
        const latestCloseKey = app.openKeys.find(
            key => !(newOpenKeys.indexOf(key) > -1)
        );
        let nextOpenKeys = [];
        if (latestOpenKey) {
            nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
        }
        if (latestCloseKey) {
            nextOpenKeys = this.getAncestorKeys(latestCloseKey);
        }
        changeOpenKeys(nextOpenKeys);
    }

    getAncestorKeys = key => {
        const map = {
            sub3: ["sub2"]
        };
        return map[key] || [];
    };

    getMenuItem = (singleOption) => {
        const {key, label, leftIcon, children} = singleOption;
        const url = this.props.url.replace(/\/+$/, '');
        if (children) {
            return (<SubMenu key={key}
                             title={<span className="isoMenuHolder">
                                 <Icon component={leftIcon}/>
                                 <span className="nav-text">
                                     <IntlMessages id={label}/>
                                 </span>
                             </span>}>
                {children.map(child => {
                    const linkTo = child.withoutDashboard ? `/${child.key}` : `${url}/${child.key}`;
                    return (
                        <Menu.Item key={child.key}>
                            <Link to={linkTo}>
                                <IntlMessages id={child.label}/>
                            </Link>
                        </Menu.Item>
                    );
                })}
            </SubMenu>);
        }
        if (key === 'dashboard') {
            return (
                <Menu.Item key={key} onClick={() => {
                    this.props.reportFetch({});
                    this.props.savedReportClear();
                }}>
                    <Link to={`${url}/`}>
                    <span className="isoMenuHolder">
                        <Icon component={leftIcon}/>
                        <span className="nav-text">
                            <IntlMessages id={label}/>
                        </span>
                    </span>
                    </Link>
                </Menu.Item>);
        }
        return (
            <Menu.Item key={key}>
                <Link to={`${url}/${key}`}>
                    <span className="isoMenuHolder">
                        <Icon component={leftIcon}/>
                        <span className="nav-text">
                            <IntlMessages id={label}/>
                        </span>
                    </span>
                </Link>
            </Menu.Item>);
    };

    reportTooltip = (savedReportProperties) => (
        [
            <u key='report_name'><b style={{fontSize: 13}}>{savedReportProperties.name}</b></u>,
            <br key='br'/>,
            <span key='report_description'
                  style={{fontSize: 12, color: theme.palette.grayscale[11]}}>
                {savedReportProperties.description}</span>,
            <ReadOnlyReportProperties key='report_properties'
                                      inTooltip
                                      expandTimeDimensions
                                      reportProperties={{...savedReportProperties, ...savedReportProperties.reportProperties}}/>,
            <span key='report_last_modification'
                  style={{fontSize: 12, color: theme.palette.grayscale[11]}}>
                <b>Last Modified: </b> {savedReportProperties.lastModificationTime}</span>,

        ]
    );

    reportToMenuItem = (savedReportProperties) => {
        return <Menu.Item key={`${savedReportProperties.id}`}
                          onClick={() => this.props.savedReportFetch(savedReportProperties)}
                          disabled={this.props.isLoading}
        >
            <Tooltip placement="right"
                     overlayStyle={basicStyle.tooltipStyle}
                     title={this.reportTooltip(savedReportProperties)}>
                <Link to="/dashboard">
                    <div className='title'>{savedReportProperties.name}</div>
                    <div className='badge'>
                        <Badge
                            count={savedReportProperties.isAlertEnabled ? <Icon component={MaterialRoundedMail}/> : 0}/>
                    </div>
                </Link>
            </Tooltip>
        </Menu.Item>
    };

    getReportsMenu = () => (
        <SubMenu
            key='reports'
            title={
                <span className="isoMenuHolder">
                    {<Icon component={ReportSVG}/>}
                    <span className="nav-text"> Reports </span>
                </span>
            }>
            {this.props.reports ? this.props.reports.map(this.reportToMenuItem) : ''}
            <Menu.Item key={'create-saved-report'}
                       onClick={() => {
                           fireEventToGoogleAnalytics('Saved Reports', 'save using sidebar');
                           this.props.changeReportEditMode(this.props.userEmail)
                       }}
                       disabled={this.props.isLoading}
                       style={{backgroundColor: '#434871'}}
            >
                <Link to="/dashboard">
                    <div className='title'>
                        <Icon component={ReportCreationSVG}/>Save Report...
                    </div>
                </Link>
            </Menu.Item>
        </SubMenu>
    );

    render() {
        const collapsed = clone(this.props.app.collapsed) && !clone(this.props.app.openDrawer);
        const mode = collapsed === true ? "vertical" : "inline";
        const onMouseEnter = event => {
            if (this.props.app.openDrawer === false) {
                this.props.toggleOpenDrawer();
            }
        };
        const onMouseLeave = () => {
            if (this.props.app.openDrawer === true) {
                this.props.toggleOpenDrawer();
            }
        };
        return (
            <DashboardSidebarWrapper>
                <Sider
                    trigger={null}
                    collapsible={true}
                    collapsed={collapsed}
                    width={240}
                    className="isomorphicSidebar"
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                >
                    <Logo collapsed={collapsed} light={true}/>
                    <Scrollbars style={{height: this.props.height - 70}}>
                        <Menu
                            onClick={this.handleClick}
                            theme="dark"
                            className="isoDashboardMenu"
                            mode={mode}
                            openKeys={collapsed ? [] : this.props.app.openKeys}
                            selectedKeys={this.props.app.current}
                            onOpenChange={this.onOpenChange}
                        >

                            {options.filter(option => ((this.props.isUserMaster && option.forMasterUsers) ||
                                ((this.props.hasBlockingPolicyPermissions || this.props.isUserMaster) && option.onlyForBlockingPolicyPermissions) ||
                                (!option.forMasterUsers && !option.onlyForBlockingPolicyPermissions))).map(singleOption =>
                                this.getMenuItem(singleOption)
                            )}
                            {this.getReportsMenu()}
                        </Menu>
                        <SolutionsMenu customizedTheme={this.props.customizedTheme}
                                       mode={mode}
                                       collapsed={collapsed}
                                       isUserMaster={this.props.isUserMaster}
                                       pid={this.props.pid}
                                       userEnabledSolutions={this.props.userEnabledSolutions}
                                       currentSolutions={this.props.currentSolutions}
                                       filteredSolutions={this.props.filteredSolutions}
                                       pidPurchasedSolutions={this.props.pidPurchasedSolutions}
                        />
                    </Scrollbars>
                </Sider>
            </DashboardSidebarWrapper>
        );
    }
}

export default connect(
    state => {
        const enabledSolutions = state.ReportsFetch.reportProperties.pid.enabled_features.split(',');
        return {
            app: state.App,
            customizedTheme: state.ThemeSwitcher.sidebarTheme,
            height: state.App.height,
            pids: state.UsersFetch.pids,
            isUserMaster: state.UsersFetch.isMaster,
            userEnabledSolutions: enabledSolutions,
            currentSolutions: state.ReportsFetch.solutions,
            isLoading: state.ReportsFetch.loading || state.UsersFetch.loading,
            filteredSolutions: state.ReportsFetch.reportProperties.filteredSolutions,
            pid: state.ReportsFetch.reportProperties.pid,
            pidPurchasedSolutions: state.ReportsFetch.reportProperties.pid.purchased_features ? state.ReportsFetch.reportProperties.pid.purchased_features.split(',') : enabledSolutions,
            reports: state.UsersFetch.isMaster ?
                state.UsersFetch.reports
                    .filter(report => report.pid.pid === state.ReportsFetch.reportProperties.pid.pid)
                : state.UsersFetch.reports,
            userEmail: state.UsersFetch.username,
            hasBlockingPolicyPermissions: state.UsersFetch.updateBlockingPermissions
        }
    },
    {
        toggleOpenDrawer,
        changeOpenKeys,
        changeCurrent,
        changeReportEditMode,
        toggleCollapsed,
        savedReportFetch,
        reportFetch,
        savedReportClear
    }
)(Sidebar);
