import React, {Component} from "react";
import PropTypes from 'prop-types';
import moment from "moment";
import clone from "clone";
import TimeRangePickerWrapper from "./dateRangePicker.style"
import {DateRangepicker} from "../../../components/uielements/datePicker";
import {MaterialRoundedTodaySVG} from "../icons/icons";
import {Icon} from "antd";
import {DATETIME_FORMAT_NO_SECONDS} from "../../../settings";
import {presetTimeRanges} from "../../containers/dashboard/config";
import Switch from "../../../components/uielements/switch";


export const TIME_UNITS = {HOUR: 'hour', DAY: 'day', WEEK: 'week', MONTH: 'month'};

const SwitchStyle = {
    marginRight: 6,
};

export default class TimeRangePicker extends Component {

    getLastValidMoment = () => {
        const currentMoment = moment.utc().utcOffset(this.props.timeZone);
        return clone(currentMoment).endOf(TIME_UNITS.DAY);
    }

    disabledTime = (time) => {
        return (time && time > this.getLastValidMoment()) || (time && (time.minutes > 0 && time.minutes < 59))
    };

    isFutureTime = (timeToCheck) => {
        let lastValidTime = this.getLastValidMoment();
        return timeToCheck > lastValidTime
    };

    handleTimeRangeChange = (value) => {
        let ValidFromDateTime = value[0].minutes() > 0 ? value[0].endOf(TIME_UNITS.HOUR) : value[0];
        let ValidToDateTime = value[1].minutes() > 0 ? value[1].endOf(TIME_UNITS.HOUR) : value[1];
        if (this.isFutureTime(value[1])) {
            const currentMoment = moment.utc().utcOffset(this.props.timeZone);
            ValidToDateTime = clone(currentMoment).subtract(1, TIME_UNITS.HOUR).startOf(TIME_UNITS.HOUR);
        }
        this.props.onTimeRangeChange(ValidFromDateTime, ValidToDateTime);
    };

    getTodayRange = () => {
        let lastValidTime = this.getLastValidMoment();
        let todayStart = clone(lastValidTime).startOf(TIME_UNITS.DAY);
        return [todayStart, lastValidTime]
    }

    isRangeIncludedInTimeRange = (fullRange, includedRange) => {
        return fullRange[0] <= includedRange[0] && fullRange[1] >= includedRange[1]
    }

    isDayIncluded = (dayRange) => {
        return this.isRangeIncludedInTimeRange(
            [this.props.fromDateTime, this.props.toDateTime], dayRange)
    }

    isYesterdayIncluded = (yesterdayRanges) => {
        return this.props.fromDateTime.format('L') === yesterdayRanges[0].format('L')
            || this.props.toDateTime.format('L') === yesterdayRanges[0].format('L')
            || this.isDayIncluded(yesterdayRanges)
    }

    onIncludeTodaySwitch = (isTodayIncluded, todayRange) => {
        if (isTodayIncluded) {
            localStorage.setItem("includeToday", "false");
            this.handleTimeRangeChange([clone(this.props.fromDateTime),
                clone(todayRange[0]).subtract(1, TIME_UNITS.HOUR).endOf(TIME_UNITS.DAY)]);
        } else {
            localStorage.setItem("includeToday", "true");
            this.handleTimeRangeChange([clone(this.props.fromDateTime), clone(todayRange[1])]);
        }
    }

    includeTodaySwitch = (timeRanges) => {
        const todayRange = this.getTodayRange();
        const isTodayIncluded = this.isDayIncluded(todayRange);
        const isYesterdayIncluded = this.isYesterdayIncluded(timeRanges.Yesterday)
        if (isYesterdayIncluded) {
            return <div>
                <Switch checked={isTodayIncluded}
                        size="small"
                        style={SwitchStyle}
                        onClick={() => this.onIncludeTodaySwitch(isTodayIncluded, todayRange)}/>
                Include today
            </div>
        } else return ''
    }

    render() {
        const currentMoment = moment.utc().utcOffset(this.props.timeZone);
        const timeRanges = presetTimeRanges(currentMoment, localStorage.getItem('includeToday') === "true");
        return <TimeRangePickerWrapper>
            <DateRangepicker
                value={[this.props.fromDateTime, this.props.toDateTime]}
                ranges={timeRanges}
                showTime
                disabledDate={this.disabledTime}
                disabledTime={this.disabledTime}
                format={DATETIME_FORMAT_NO_SECONDS}
                onChange={this.handleTimeRangeChange}
                separator='_'
                allowClear={false}
                suffixIcon={<Icon component={MaterialRoundedTodaySVG}/>}
                renderExtraFooter={() => this.includeTodaySwitch(timeRanges)}
            />
        </TimeRangePickerWrapper>

    }
}

TimeRangePicker.propTypes = {
    fromDateTime: PropTypes.instanceOf(moment),
    toDateTime: PropTypes.instanceOf(moment),
    onTimeRangeChange: PropTypes.func.isRequired,
    timeZone: PropTypes.string
};

TimeRangePicker.defaultProps = {
    fromDateTime: moment.utc().subtract(1, TIME_UNITS.DAY).startOf(TIME_UNITS.DAY),
    toDateTime: moment.utc().subtract(1, TIME_UNITS.DAY).endOf(TIME_UNITS.DAY),
    timeZone: '+00:00'
};