import SuperFetch from './superFetch'
import {
    DATETIME_FORMAT,
    DATETIME_FORMAT_NO_SECONDS,
    useApiMock,
} from '../settings'
import { METRICS_GROUPS } from '../protectedMediaDashboardApp/config'
import { ReportPropertiesQueryParameters } from './utility'
import {
    MINIMUM_IMPRESSIONS_FOR_SAMPLE,
    PERCENTAGE_SUFFIX, SEPARATED_COLUMNS_DEFAULT,
} from '../protectedMediaDashboardApp/containers/dashboard/config'
import {
    REPORTS_CHARTS_RESPONSE_MOCK,
    REPORTS_DATA_RESPONSE_MOCK,
    REPORTS_INSIGHTS_RESPONSE_MOCK,
    REPORTS_HIGH_RATE_MESSAGES_RESPONSE_MOCK,
    REPORTS_SMART_AGENT_RESPONSE_MOCK,
} from './fakeReportHelperData'

const DEFAULT_SMART_AGENT_METRIC = 'ivt_total_ivt'

class ReportHelper {
    fetchReportData = async (reportId) => {
        if (!reportId) {
            return { error: 'query Parameters are empty' }
        }
        if (useApiMock) {
            return this.checkReport(reportId, REPORTS_DATA_RESPONSE_MOCK)
        }
        return await SuperFetch.get(`reports/table_data?${reportId}`).then(
            (result) => this.checkReport(reportId, result)
        )
    }

    checkReport = (reportId, data) => {
        if (data.status === 504) {
            return {
                error: 'Sorry, There is no data to show for this filters :(',
            }
        }
        if (!data.schema) {
            return {
                error: 'Sorry, There is no data to show for this filters :(',
            }
        }
        if (!data.timeline) {
            return {
                error: 'Sorry, There is no data to show for this filters :(',
            }
        }
        try {
            const rows = data.timeline.map((row) => {
                let result = {}
                const rowTotal = row[data.schema.indexOf('total')]
                data.schema.forEach((field, index) => {
                    if (
                        (field.startsWith('mrc_') &&
                            result.integration !== 'JS' &&
                            result.integration !== 'IMG' &&
                            result.integration !== 'SIMG' &&
                            result.integration !== 'ROKU_IMP') ||
                        (field.startsWith('sr_') &&
                            (result.integration === 'RDRS' ||
                                result.integration === 'PREBID_LOCAL_CACHE'))
                    ) {
                        result[field] = -1
                    } else if (field === 'sr_calculated_total') {
                        result.sr_calculated_total = row[index]
                        result.sr_measured_rate =
                            (rowTotal / result.sr_calculated_total) * 100
                    } else if (field === 'sr_confidence_level') {
                        result.sr_confidence_level =
                            rowTotal < MINIMUM_IMPRESSIONS_FOR_SAMPLE
                                ? -1
                                : row[index]
                    } else {
                        result[field] = row[index]
                    }
                })
                result.mrc_auto_refresh = result.mrc_net_impressions
                result.mrc_non_auto_refresh =
                    result.mrc_gross_impressions - result.mrc_net_impressions
                result.mrc_auto_refresh_unconfigured = 0
                result.mrc_continuous_play = result.mrc_net_impressions
                result.mrc_non_continuous_play =
                    result.mrc_gross_impressions - result.mrc_net_impressions
                result.mrc_continuous_play_unconfigured = 0

                if (
                    result.hasOwnProperty('sr_calculated_total') &&
                    (result.integration === 'JS' ||
                        result.integration === 'IMG')
                ) {
                    result.sampled_mrc_measured_rate = result.sr_measured_rate
                    result.sampled_mrc_calculated_total =
                        result.sr_calculated_total
                    if (rowTotal >= MINIMUM_IMPRESSIONS_FOR_SAMPLE) {
                        result.sampled_mrc_confidence_level =
                            result.sr_confidence_level
                        if (result.hasOwnProperty('mrc_net_impressions') >= 0) {
                            result.sampled_mrc_gross_impressions = Math.round(
                                result.sr_calculated_total *
                                    (result.mrc_gross_impressions / rowTotal)
                            )
                            result.sampled_mrc_net_impressions = Math.round(
                                result.sampled_mrc_gross_impressions *
                                    (result.mrc_net_impressions /
                                        result.mrc_gross_impressions)
                            )
                            result.sampled_mrc_givt = Math.round(
                                result.sampled_mrc_gross_impressions *
                                    (result.mrc_givt /
                                        result.mrc_gross_impressions)
                            )
                        }
                        if (
                            result.hasOwnProperty(
                                'mrc_tracked_ads_net_impressions'
                            ) >= 0
                        ) {
                            result.sampled_mrc_tracked_ads = Math.round(
                                result.sr_calculated_total *
                                    (result.mrc_tracked_ads / rowTotal)
                            )
                            result.sampled_mrc_tracked_ads_net_impressions =
                                Math.round(
                                    result.sampled_mrc_tracked_ads *
                                        (result.mrc_tracked_ads_net_impressions /
                                            result.mrc_tracked_ads)
                                )
                            result.sampled_mrc_tracked_ads_givt = Math.round(
                                result.sampled_mrc_tracked_ads *
                                    (result.mrc_tracked_ads_givt /
                                        result.mrc_tracked_ads)
                            )
                        }
                        if (
                            result.hasOwnProperty(
                                'mrc_total_net_impressions'
                            ) >= 0
                        ) {
                            result.sampled_mrc_total_net_impressions =
                                Math.round(
                                    result.sampled_mrc_gross_impressions *
                                        (result.mrc_total_net_impressions /
                                            result.mrc_gross_impressions)
                                )
                            result.sampled_mrc_sivt = Math.round(
                                result.sampled_mrc_gross_impressions *
                                    (result.mrc_sivt /
                                        result.mrc_gross_impressions)
                            )
                        }
                        if (
                            result.hasOwnProperty(
                                'mrc_tracked_ads_total_net_impressions'
                            ) >= 0
                        ) {
                            result.sampled_mrc_tracked_ads_total_net_impressions =
                                Math.round(
                                    result.sampled_mrc_tracked_ads *
                                        (result.mrc_tracked_ads_total_net_impressions /
                                            result.mrc_tracked_ads)
                                )
                            result.sampled_mrc_tracked_ads_sivt = Math.round(
                                result.sampled_mrc_tracked_ads *
                                    (result.mrc_tracked_ads_sivt /
                                        result.mrc_tracked_ads)
                            )
                        }
                    }
                }
                result.total_ivt =
                    parseInt(result.af_fraud) + parseInt(result.af_susp)
                return result
            })

            return {
                schema: data.schema,
                rows: rows,
                reportId: reportId,
            }
        } catch (e) {
            console.log(e)

            return { error: 'Server Error' }
        }
    }

    fetchChartsData = async (reportId) => {
        if (!reportId) {
            return { error: 'query Parameters are empty' }
        }
        if (useApiMock) {
            return this.checkGraphResponse(REPORTS_CHARTS_RESPONSE_MOCK)
        }
        return await SuperFetch.get(`reports/charts?${reportId}`).then(
            this.checkGraphResponse
        )
    }

    checkGraphResponse = (data) => {
        if (!data.schema) {
            return {
                error: 'data has no schema',
            }
        }
        if (!data.timeline) {
            return {
                error: 'data has no content',
            }
        }
        try {
            return {
                schema: data.schema,
                rows: data.timeline,
                total: data.total,
                totalBySolution: {
                    antiFraud: {
                        fraud: data.af_fraud,
                        suspicious: data.af_susp,
                    },
                    ivt: {
                        standard: data.ivt_givt,
                        enhanced: data.ivt_sivt,
                    },
                },
                dataByCategory: {
                    fraud: {
                        categories:
                            METRICS_GROUPS.fraudulent.metricsByCategories,
                        fraud: METRICS_GROUPS.fraudulent.metricsByCategories.map(
                            (category) => data[category.key]
                        ),
                        suspicious:
                            METRICS_GROUPS.suspicious.metricsByCategories.map(
                                (category) => data[category.key]
                            ),
                    },
                    brandProtection: {
                        categories: [
                            ...METRICS_GROUPS.brandProtection.masterMetrics,
                            ...METRICS_GROUPS.brandProtection
                                .metricsByCategories,
                        ],
                        impressions: [
                            ...METRICS_GROUPS.brandProtection.masterMetrics,
                            ...METRICS_GROUPS.brandProtection
                                .metricsByCategories,
                        ].map((category) => data[category.key]),
                    },
                    brandProtectionBlocked: {
                        categories: [
                            ...METRICS_GROUPS.brandProtectionBlocked.masterMetrics,
                            ...METRICS_GROUPS.brandProtectionBlocked
                                .metricsByCategories,
                        ],
                        impressions: [
                            ...METRICS_GROUPS.brandProtectionBlocked.masterMetrics,
                            ...METRICS_GROUPS.brandProtectionBlocked
                                .metricsByCategories,
                        ].map((category) => data[category.key]),
                    },
                    ivt: {
                        categories: [
                            ...METRICS_GROUPS.generalIvt.metricsByCategories,
                            ...METRICS_GROUPS.sophisticatedIvt
                                .metricsByCategories,
                        ],
                        standardIVT:
                            METRICS_GROUPS.generalIvt.metricsByCategories.map(
                                (category) => data[category.key]
                            ),
                        EnhancedIVT:
                            METRICS_GROUPS.sophisticatedIvt.metricsByCategories.map(
                                (category) => data[category.key]
                            ),
                    },
                },
            }
        } catch (e) {
            console.log(e)

            return { error: 'Server Error' }
        }
    }

    fetchInsightsData = async (reportId) => {
        if (!reportId) {
            return { error: 'query Parameters are empty' }
        }
        if (useApiMock) {
            return this.checkInsightResponse(REPORTS_INSIGHTS_RESPONSE_MOCK)
        }
        return await SuperFetch.get(`reports/insights?${reportId}`).then(
            this.checkInsightResponse
        )
    }

    getInsightByKeys = (keys, data, title) => {
        if (data && data.top && data.schema) {
            const labelsIndex = keys.map((key) => data.schema.indexOf(key))
            const totalIndex = data.schema.indexOf('total')
            const fraudIndex = data.schema.indexOf('af_fraud')
            const suspIndex = data.schema.indexOf('af_susp')
            return {
                title: title,
                dimensions: keys,
                values: data.top.slice(0, 3).map((result) => ({
                    labels: labelsIndex.map((labelIndex) =>
                        result[labelIndex]
                            ? result[labelIndex].replace(/\[[^)]*] */g, '')
                            : ''
                    ),
                    fraud: result[fraudIndex],
                    susp: result[suspIndex],
                    total: result[totalIndex],
                })),
            }
        }
        return []
    }

    checkInsightResponse = (data) => {
        try {
            let insightsByKeys = []
            insightsByKeys = [
                this.getInsightByKeys(['tid'], data.tid, 'by Traffic Type'),
                this.getInsightByKeys(
                    ['tid', 'l1'],
                    data['tid,l1'],
                    'by Traffic Type and Level 1'
                ),
            ]
            return insightsByKeys
        } catch (e) {
            console.log(e)
            return { error: 'Server Error' }
        }
    }

    fetchHighRatesMessagesData = async (reportId) => {
        if (!reportId) {
            return { error: 'query Parameters are empty' }
        }
        if (useApiMock) {
            return this.checkHighRatesMessagesResponse(
                REPORTS_HIGH_RATE_MESSAGES_RESPONSE_MOCK
            )
        }
        return await SuperFetch.get(
            `reports/high_rate_messages?${reportId}`
        ).then(this.checkHighRatesMessagesResponse)
    }

    checkHighRatesMessagesResponse = (data) => {
        try {
            if (data) {
                return data
            }
            return {}
        } catch (e) {
            console.log(e)
            return { error: 'Server Error' }
        }
    }

    FilterObject = (filter, isNumeric) => ({
        field: filter.isPercentage
            ? `${filter.field}${PERCENTAGE_SUFFIX}`
            : filter.field,
        relation_operator: filter.relationOperator,
        value: filter.values || filter.value,
        is_value_numeric: isNumeric,
    })

    reportPropertiesObject = (reportProperties, timeDimensions) => ({
        dimensions: [...reportProperties.dimensions],
        metrics: reportProperties.selectedMetrics,
        time_dimensions: timeDimensions,
        conditions: reportProperties.conditions.map((condition) =>
            this.FilterObject(condition, false)
        ),
        filters: reportProperties.filters.map((filter) =>
            this.FilterObject(filter, true)
        ),
    })

    advancedCsvJobRequestData(
        reportProperties,
        timeDimensions,
        currentSolutions,
        compression
    ) {
        let reportPropertiesObject = this.reportPropertiesObject(
            reportProperties,
            timeDimensions
        )

        reportPropertiesObject.rewriteUILevels = {}

        if (reportProperties.pid.dimensionsTitles) {
             reportPropertiesObject.dimensions.forEach((dimension) => {
                const renamedDimension = reportProperties.pid.dimensionsTitles[dimension]
                if (renamedDimension) {
                    reportPropertiesObject.rewriteUILevels[dimension] = renamedDimension
                }
            })
        }

        let formData = new FormData()
        formData.append(
            'report_properties',
            JSON.stringify(reportPropertiesObject)
        )
        formData.append(
            'from_datetime',
            reportProperties.timeRange.from.format(DATETIME_FORMAT)
        )
        formData.append(
            'to_datetime',
            reportProperties.timeRange.to.format(DATETIME_FORMAT)
        )
        formData.append('enabled_features', currentSolutions)
        formData.append('timezone', reportProperties.timeZone)
        formData.append('compression', compression)
        formData.append('parameters_mapping_columns', reportProperties.parametersMappingColumns || SEPARATED_COLUMNS_DEFAULT)

        return formData
    }

    startReportExportJob = async (
        reportProperties,
        timeDimensions,
        currentSolutions,
        compression
    ) => {
        if (!reportProperties) {
            return { error: 'query Parameters are empty' }
        }
        return await SuperFetch.post(
            `export/export_report/${reportProperties.pid.pid}/`,
            this.advancedCsvJobRequestData(
                reportProperties,
                timeDimensions,
                currentSolutions,
                compression
            )
        )
    }

    openSupportTicket = async (issueDetails, reportProperties, contactInfo) => {
        let formData = new FormData()
        formData.append('issue_details', JSON.stringify(issueDetails))
        formData.append(
            'url',
            ReportPropertiesQueryParameters.getQueryParametersFromReportProperties(
                reportProperties
            )
        )
        formData.append(
            'report_properties',
            JSON.stringify({
                ...this.reportPropertiesObject(reportProperties, []),
                pid: reportProperties.pid.pid,
                time_range: {
                    from: reportProperties.timeRange.from.format(
                        DATETIME_FORMAT_NO_SECONDS
                    ),
                    to: reportProperties.timeRange.to.format(
                        DATETIME_FORMAT_NO_SECONDS
                    ),
                },
            })
        )
        formData.append('contact_info', JSON.stringify(contactInfo))

        return await SuperFetch.post(`reports/open_support_ticket/`, formData)
    }

    checkSmartAgentResponse = (data) => {
        try {
            if (
                data.ivt_total_ivt &&
                data.ivt_total_ivt.rule_rows_mapping &&
                data.ivt_total_ivt.rows
            ) {
                return data
            }
            return {}
        } catch (e) {
            console.log(e)
            return { error: 'Server Error' }
        }
    }

    fetchSmartAgentData = async (reportId, metric) => {
        if (!reportId) {
            return { error: 'query Parameters are empty' }
        }
        if (!metric) {
            metric = DEFAULT_SMART_AGENT_METRIC
        }
        if (useApiMock) {
            return this.checkSmartAgentResponse(
                REPORTS_SMART_AGENT_RESPONSE_MOCK
            )
        }
        return await SuperFetch.get(
            `reports/smart_agent?${reportId}&metric=${metric}`
        ).then(this.checkSmartAgentResponse)
    }
}

export default new ReportHelper()
