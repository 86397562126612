import actions from './actions';

const initState = {
    isActivated: false,
    isPinned: localStorage.getItem('filtersSidebarDefault') !== 'unpinned'
};

export default function (state = initState, action) {
    switch (action.type) {
        case actions.ACTIVATE_LANG_MODAL:
            return {
                ...state,
                isActivated: !state.isActivated
            };
        case actions.SWITCH_PINNING:
            return {
                ...state,
                isPinned: !state.isPinned,
                isActivated: !state.isPinned
            };

        default:
            return state;
    }
}
