import React from 'react';
import ReactDOM from 'react-dom';
import DashApp from './dashApp';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css';
import message from './components/uielements/message';
import Button from './components/uielements/button';

function onUpdate(registration) {
    message.info(<span>New version of the dashboard is available. <Button onClick={() => {
        window.location.reload();
    }}>Click here to refresh</Button></span>, 0);
}

function onSuccess(registration) {
}

ReactDOM.render(<DashApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister({onUpdate, onSuccess});
