import asyncComponent from '../helpers/AsyncFunc';

const routes = [
    {
        path: '',
        component: asyncComponent(() => import('./containers/dashboard/dashboardTemplate'))
    },
    {
        path: 'dashboard',
        component: asyncComponent(() => import('./containers/dashboard/dashboardTemplate'))
    },
    {
        path: 'users',
        component: asyncComponent(() => import('./containers/users/users')),
    },
    {
        path: 'docs',
        component: asyncComponent(() => import('./containers/docs/docs')),
    },
    {
        path: 'integration',
        component: asyncComponent(() => import('./containers/integration/integrations')),
    },
    {
        path: 'blocking_policy',
        component: asyncComponent(() => import('./containers/blockingPolicy/blockingPolicies')),
    },
    {
        path: 'custom_blocking_lists',
        component: asyncComponent(() => import('./containers/blockingPolicy/CustomBlockingLists')),
    },
    {
        path: 'usage_notifications',
        component: asyncComponent(() => import('./containers/usageNotification/usageNotification')),
    }
];
export default routes;
