import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Avatar} from "antd";
import TopbarDropdownWrapper from './topbarDropdown.style';
import Popover from '../../components/uielements/popover';
import IntlMessages from '../../components/utility/intlMessages';
import authAction from '../../redux/auth/actions';
import theme from "../../settings/themes/protectedmediatheme";


const { logout } = authAction;

class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  render() {
      const tamEmail = `${this.props.tam}@protected.media`
      const content = (
          <TopbarDropdownWrapper className="isoUserDropdown">
              <a className="isoDropdownLink" href={`mailto:${this.props.tam ? tamEmail : 'cs@protected.media'}`}>
                  {`Contact your CS${this.props.tam ? ` (${tamEmail})` : ''}`}
              </a>

              <a className="isoDropdownLink" onClick={this.props.logout} href="# ">
                  <IntlMessages id="topbar.logout"/>
              </a>
          </TopbarDropdownWrapper>
      );
      return (
          <div>
              <Popover
                  content={content}
                  trigger="click"
                  visible={this.state.visible}
                  onVisibleChange={this.handleVisibleChange}
                  arrowPointAtCenter={true}
                  placement="bottomLeft"
              >
                  <Avatar style={{
                      color: theme.palette.primary[1],
                      backgroundColor: theme.palette.primary[6]
                  }} size='large'> {this.props.userName} </Avatar>
              </Popover>
          </div>
      );
  }
}

function mapStateToProps(state) {
    return {
        tam: state.ReportsFetch.reportProperties.pid.tam,
    }
}

export default connect(
    mapStateToProps,
    {logout}
)(TopbarUser);
