import React, {Component} from "react";
import {connect} from "react-redux";
import {Icon} from "antd";
import SolutionsMenuWrapper from "./solutionsMenuWrapper.style";
import {SOLUTIONS} from "../../config.js"
import {ALL_SOLUTIONS} from "../dashboard/config";
import ReportsActions from "../../redux/reports/actions";
import {SolutionsSVG} from "../../components/icons/icons";
import Switch from '../../../components/uielements/switch';
import Menu from "../../../components/uielements/menu";
import { fireEventToGoogleAnalytics, getMetricsBySolution, sortOrAddFromSortedArray } from '../../../helpers/utility'
import Tooltip from '../../../components/uielements/tooltip';
import basicStyle from "../../../settings/basicStyle";
import message from "../../../components/feedback/message";
import Badge from "../../../containers/Uielements/Badge/badge.style";

const SubMenu = Menu.SubMenu;

const {changeReportSolutions, addMetricsToReport} = ReportsActions;

const SwitchStyle = {
    left: 'auto',
    position: 'absolute',
    right: 25,
    top: 14,
    borderColor: '#3c4273',
    backgroundColor: '#3c4273'
};

class SolutionsMenu extends Component {

    handleSolutionSwitch = (solutionName) => {
        const {changeReportSolutions, addMetricsToReport} = this.props;
        let newSolutionsList = this.props.currentSolutions;

        if (newSolutionsList.indexOf(solutionName) < 0) {
            newSolutionsList = sortOrAddFromSortedArray(newSolutionsList, ALL_SOLUTIONS, solutionName);
            fireEventToGoogleAnalytics('Solutions Menu', 'Add Product', solutionName)
            const solutionMetrics = getMetricsBySolution(this.props.isUserMaster, solutionName)
            addMetricsToReport(solutionMetrics)
        } else {
            if (this.props.filteredSolutions.indexOf(solutionName) >= 0) {
                message.error('The current report is filtered by one of the metrics' +
                    ' of this solution. please remove the filter in order to hide the solution', 3);
            } else {
                newSolutionsList = newSolutionsList.filter(solution => solution !== solutionName);
                fireEventToGoogleAnalytics('Solutions Menu', 'Remove Product', solutionName)
            }
        }
        localStorage.setItem('solutions', newSolutionsList.join(','));
        changeReportSolutions(newSolutionsList);
    };

    getMenuItem = (solutionName, submenuStyle, pidPurchasedSolutions) => {
        const solution = SOLUTIONS[solutionName];
        const solutionSwitch = <Switch checked={this.props.currentSolutions.indexOf(solutionName) >= 0}
                                       size="small"
                                       style={SwitchStyle}
                                       onClick={() => this.handleSolutionSwitch(solutionName)}/>;
        let title = pidPurchasedSolutions.indexOf(solutionName) >= 0 && this.props.isUserMaster ?
            [<b key='is-available' style={{fontSize: 13}}>This feature is enabled for the customer<br/><br/></b>] : []
        title.push(solution.description)
        return <Menu.Item style={{...submenuStyle, backgroundColor: "inherit"}}
                          key={solutionName}>
            <Tooltip overlayStyle={basicStyle.tooltipStyle} placement="right"
                     title={title}
                     onVisibleChange={(isVisible) => isVisible ?
                         fireEventToGoogleAnalytics('Solutions Menu', 'Hover', solution.title) : null}>
                {pidPurchasedSolutions.indexOf(solutionName) >= 0 && this.props.isUserMaster?
                    <div>
                    <Badge dot={false} color='#787da2' className='enabledSolutionBadge'>
                    {solution.title}
                </Badge>
                    </div>: solution.title}
                {this.props.userEnabledSolutions.indexOf(solutionName) < 0 ? '' : solutionSwitch}
            </Tooltip>
        </Menu.Item>
    };

    render() {
        const {mode, collapsed, pidPurchasedSolutions} = this.props;

        const submenuStyle = {
            backgroundColor: '#3b3f64',
            color: '#787da2',
            fill: '#787da2',
            cursor: 'default'
        };

        if (!collapsed) {
            return <SolutionsMenuWrapper>
                <Menu
                    onClick={() => {
                    }}
                    onMouseEnter={() => {
                    }}
                    theme="dark"
                    className="isoDashboardMenu solutionsMenu"
                    mode={mode}
                    openKeys={['solutions']}
                    defaultOpenKeys={['solutions']}
                    selectable={false}
                >
                    <SubMenu
                        key="solutions"
                        expandIcon={<i/>}
                        title={
                            <span className="isoMenuHolder">
                    <Icon component={SolutionsSVG}/>
                    <span className="nav-text">
                      Solutions
                    </span>
                  </span>
                        }>
                        {Object.keys(SOLUTIONS)
                            .filter(solutionName => !SOLUTIONS[solutionName].forMasterUsers)
                            .map((solutionName) => this.getMenuItem(solutionName, submenuStyle, pidPurchasedSolutions))}
                        {this.props.isUserMaster ?
                            [<Menu.Item style={{...submenuStyle, backgroundColor: "inherit", marginLeft: '-30px'}}
                                        disabled
                                        key='masterUsers'>
                                - Master Users Solutions -
                            </Menu.Item>,
                                Object.keys(SOLUTIONS)
                                    .filter(solutionName => SOLUTIONS[solutionName].forMasterUsers)
                                    .map((solutionName) => this.getMenuItem(solutionName, submenuStyle, pidPurchasedSolutions))
                            ] : ''}
                    </SubMenu>
                </Menu>
            </SolutionsMenuWrapper>
        }
        return <div/>
    }
}

export default connect(
    null,
    {changeReportSolutions, addMetricsToReport}
)(SolutionsMenu);

