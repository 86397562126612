import React, {Component} from "react";
import {InputGroup} from "../../../components/uielements/input";
import Select, {SelectOption} from "../../../components/uielements/select";
import message from "../../../components/feedback/message";
import FilterInputWrapper from "./filterInput.style";
import basicStyle from "../../../settings/basicStyle";
import {Cascader, Col, InputNumber, Row} from "antd";
import {RadioButton, RadioGroup} from "../../../components/uielements/radio";
import Button from "../../../components/uielements/button";
import {TOTAL_COLUMN} from "../../containers/dashboard/config";
import RangeMetricFilter from "./rangeMetricFilter";

const Option = SelectOption;

function cascaderFilter(inputValue, path) {
    return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
}

function getDefaultFilter(relationOperators) {
    const field = {...TOTAL_COLUMN, path: [TOTAL_COLUMN.key]};
    return {
        field: field,
        min: 0,
        max: 100,
        isPercentage: false,
        relationOperator: Object.keys(relationOperators).filter(key => !relationOperators[key].disabled)[0],
        value: null
    }
}

export default class FilterInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...getDefaultFilter(props.relationOperators),
        };
        this.generateOptionsFromRelationOperators = this.generateOptionsFromRelationOperators.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleRelationOperatorChange = this.handleRelationOperatorChange.bind(this);
        this.handlePercentageValueChange = this.handlePercentageValueChange.bind(this);
        this.handleAbsoluteNumbersFilterCreation = this.handleAbsoluteNumbersFilterCreation.bind(this);
        this.handleFilterCreation = this.handleFilterCreation.bind(this);
    }

    static getDerivedStateFromProps(props) {
        let newState = {}
        if (props.conditionToEdit) {
            newState = {
                field: { key: props.conditionToEdit.field, path: props.conditionToEdit.path },
                isPercentage: props.conditionToEdit.isPercentage,
                relationOperator: props.conditionToEdit.relationOperator,
                value: props.conditionToEdit.value,
                isEditMode: true
            }
            props.clearConditionToEdit()
        }
        return newState
    }

    generateOptionsFromRelationOperators = () => {
        return Object.keys(this.props.relationOperators).map(key =>
            <Option key={key}
                    disabled={this.props.relationOperators[key].disabled}>
                {this.props.relationOperators[key].name}</Option>)
    };

    handleFieldChange = (fieldPath) => {
        const fieldName = fieldPath[fieldPath.length - 1]
        let fieldProperties = {
            ...this.props.fields.find(metric => metric.key === fieldName),
            path: fieldPath
        };
        this.setState({
            field: fieldProperties,
            isPercentage: !['NumberCell', 'ScoreCell'].includes(fieldProperties.cellType)
        })
    };

    changePercentage = () => {
        this.setState({isPercentage: !this.state.isPercentage});
    }

    handleRelationOperatorChange = (relationOperator) => {
        this.setState({relationOperator})
    };

    handlePercentageValueChange = (newMinValue, newMaxValue) => {
        this.setState({min: newMinValue, max: newMaxValue});
        this.props.updateNotAllFiltersApplied(newMinValue || newMaxValue)
    };

    handleAbsoluteNumbersFilterCreation = (value) => {
        this.setState({value})
        this.props.updateNotAllFiltersApplied(value)
    }

    handleFilterCreation = () => {
        let filters = []
        if (this.state.isPercentage) {
            if (this.state.min < this.state.max) {
                if (this.state.min > 0) {
                    filters.push({
                        field: this.state.field.key,
                        path: this.state.field.path,
                        isPercentage: true,
                        relationOperator: ">=",
                        value: this.state.min
                    })
                }
                if (this.state.max < 100) {
                    filters.push({
                        field: this.state.field.key,
                        path: this.state.field.path,
                        isPercentage: true,
                        relationOperator: "<=",
                        value: this.state.max
                    })
                }
            }
        } else {
            if (this.state.field.key && this.state.value) {
                filters.push({
                    field: this.state.field.key,
                    path: this.state.field.path,
                    isPercentage: false,
                    relationOperator: this.state.relationOperator,
                    value: this.state.value
                })
            }
        }
        if (filters.length > 0) {
            this.props.onFilterCreation(filters)
            this.props.updateNotAllFiltersApplied(false);
            this.setState(getDefaultFilter(this.props.relationOperators))
        } else {
            message.error(this.state.isPercentage ? 'Please narrow the range' :
                'Please insert a value', 3);
        }
    };

    fieldsPicker = (isFull) => (
        <Cascader style={isFull ? {} : {width: "37%"}}
                  options={this.props.metricsHierarchy}
                  onChange={this.handleFieldChange}
                  value={this.state.field.path}
                  placeholder="Please select"
                  showSearch={{filter: cascaderFilter}}
                  size='large'
        />
    )

    absoluteNumbersPicker = (relationOperatorPickerWidth, valuePickerWidth, valuePickerHeight, addButtonWidth) => {
        let relationOperatorStyle = relationOperatorPickerWidth ? {width: `${relationOperatorPickerWidth}%`} : {}
        let addButtonStyle = addButtonWidth ? {width: `${addButtonWidth}%`} : {}
        let valuePickerStyle = valuePickerWidth ? {width: `${valuePickerWidth}%`, margin: 0} : {};
        if (valuePickerHeight) {
            valuePickerStyle.height = valuePickerHeight
        }
        return [<Select
            key='selectField'
            style={relationOperatorStyle}
            dropdownStyle={{fontSize: '12px'}}
            dropdownMatchSelectWidth={false}
            value={this.state.relationOperator}
            onChange={this.handleRelationOperatorChange}>
            {this.generateOptionsFromRelationOperators()}
        </Select>,
            <InputNumber
                key='inputField'
                style={valuePickerStyle}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                value={this.state.value}
                onChange={this.handleAbsoluteNumbersFilterCreation}
            />,
            <Button key='btn'
                    style={addButtonStyle}
                    shape="round"
                    type="primary"
                    className="filter-creation-button"
                    onClick={this.handleFilterCreation}>Add</Button>]
    }

    render() {
        const {rowStyle, colStyle, gutter} = basicStyle;
        const isNumOrPercentage = this.state.field.cellType === 'NumberPercentageCell';
        const isNumbersOnly = this.state.field.cellType === 'NumberCell';

        if (!this.state.field.key || isNumbersOnly) {
            return <FilterInputWrapper>
                <InputGroup compact>
                    {this.fieldsPicker()}
                    {this.absoluteNumbersPicker(12, 37, 35, 10)}
                </InputGroup>
            </FilterInputWrapper>
        }
        return <FilterInputWrapper>
            <Row style={rowStyle} gutter={gutter}>
                <Col style={colStyle} span={18}>
                    {this.fieldsPicker(true)}
                </Col>
                {isNumOrPercentage ?
                    <Col style={colStyle} span={6}>
                        <RadioGroup id='percentage-change'
                                    value={this.state.isPercentage}
                                    onChange={this.changePercentage}>
                            <RadioButton value={true}>%</RadioButton>
                            <RadioButton value={false}>#</RadioButton>
                        </RadioGroup>
                    </Col> : ''}
            </Row>

            {this.state.isPercentage ?
                <Row style={rowStyle} gutter={gutter}>
                    <Col style={colStyle} span={18}>
                        <div style={{padding: '0 6px'}}>
                            <RangeMetricFilter validRange={this.state.isPercentage ? [0, 100] : false}
                                               min={this.state.min}
                                               max={this.state.max}
                                               onMinChange={(min) => this.setState({min: min})}
                                               onMaxChange={(max) => this.setState({max: max})}
                                               isPercentage={this.state.isPercentage}
                                               handleChange={this.handlePercentageValueChange}
                                               submit={() => null}/>
                        </div>
                    </Col>
                    <Col style={colStyle} span={6}>
                        <Button style={{width: '100%'}}
                                shape="round"
                                type="primary"
                                className="filter-creation-button"
                                onClick={this.handleFilterCreation}>Add</Button>
                    </Col>
                </Row> :
                <Row style={rowStyle}>
                    <Col style={colStyle} span={24}>
                        <InputGroup compact>
                            {this.absoluteNumbersPicker(30, 40, 35, 20)}

                        </InputGroup>
                    </Col>
                </Row>
            }
        </FilterInputWrapper>
    }
}