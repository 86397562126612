import React, {Component} from "react";
import FilterInput from "./filterInput";
import AddableFiltersWrapper from "./addableFilters.style";
import Tooltip from "../../../components/uielements/tooltip";
import Tags from '../../../components/uielements/tag';
import TagWrapper from '../../../containers/Uielements/Tag/tag.style.js';
import basicStyle from "../../../settings/basicStyle";
import {getConditionTextFromCondition} from "../../../helpers/utility";
import ConditionInput from "./conditionInput";
import {CONDITIONS_RELATION_OPERATORS, FILTERS_RELATION_OPERATORS, METRICS} from "../../containers/dashboard/config";

const Tag = props => (
    <TagWrapper>
        <Tags {...props}>{props.children}</Tags>
    </TagWrapper>
);

export default class AddableFilters extends Component {

    constructor(props) {
        super(props);
        this.state = {
            conditionToEdit: null
        };
        this.generateConditionsTag = this.generateConditionsTag.bind(this);
        this.handleTagClick = this.handleTagClick.bind(this);
    }

    handleTagClick = (e, index) => {
        if (e.detail === 2) {
            // Double click handler
            this.setState({conditionToEdit: {...this.props.filters[index]}})
            this.props.onFilterEdit(index);
        }
    }

    generateConditionsTag = (filter, index, relationOperators) => {
        const filterText = getConditionTextFromCondition(filter, this.props.metricsHierarchy ? METRICS : this.props.fields, relationOperators);
        const isLongTag = filterText.length > 50;
        const tagElem = (
            <span key={filterText} style={{fontSize: 11}}>
                {index ? 'and' : null}
                <Tooltip overlayStyle={basicStyle.tooltipStyle}
                         title={`Double click to edit`}
                         align={{ offset: [5, 5] }}
                         placement={'top'}>
                    <Tag style={{marginLeft: 8}}
                        closable={!this.props.readOnly}
                        onClose={() => this.props.onFilterRemove(index)}
                        onClick={(e) => this.handleTagClick(e, index)}
                    >
                        {isLongTag ? `${filterText.slice(0, 40)}...` : filterText}
                    </Tag>
                </Tooltip>
            </span>
        );
        return isLongTag ? (
            <Tooltip overlayStyle={basicStyle.tooltipStyle}
                     title={filterText}
                     key={filterText}
                     placement={'bottom'}>{tagElem}</Tooltip>
        ) : (
            tagElem
        );
    };
    render() {
        const relationOperators =
            this.props.metricsHierarchy ? FILTERS_RELATION_OPERATORS : CONDITIONS_RELATION_OPERATORS
        const tags = this.props.filters
            .map((filter, index) => this.generateConditionsTag(filter, index, relationOperators));
        return <AddableFiltersWrapper>
            {!this.props.readOnly ?
                this.props.metricsHierarchy ?
                    <FilterInput metricsHierarchy={this.props.metricsHierarchy}
                                 fields={this.props.fields}
                                 conditionToEdit={this.state.conditionToEdit}
                                 clearConditionToEdit={() => this.setState({conditionToEdit: null})}
                                 relationOperators={relationOperators}
                                 filteredFields={this.props.filters.map(filter => filter.field)}
                                 updateNotAllFiltersApplied={this.props.updateNotAllFiltersApplied}
                                 onFilterCreation={this.props.onFilterCreation}/> :
                    <ConditionInput fields={this.props.fields}
                                    conditionToEdit={this.state.conditionToEdit}
                                    clearConditionToEdit={() => this.setState({conditionToEdit: null})}
                                    relationOperators={relationOperators}
                                    filteredFields={this.props.filters.map(filter => filter.field)}
                                    updateNotAllFiltersApplied={this.props.updateNotAllFiltersApplied}
                                    onFilterCreation={this.props.onFilterCreation}/>
                : ''}
            {tags}
        </AddableFiltersWrapper>
    }
}
