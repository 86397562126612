import theme from "./themes/protectedmediatheme";

const rowStyle = {
    width: '100%',
    display: 'flex',
    flexFlow: 'row wrap',
};
const colStyle = {
    marginBottom: '16px',
};
const gutter = 16;
const tooltipStyle = {
    fontSize: '12px',
    color: '#e2e3ea'
};
const popconfirmButtonsProps = {
    okButtonProps: {
        style:
            {
                backgroundColor: theme.palette.primary[0],
                borderColor: theme.palette.primary[1]
            }
    },
    cancelButtonProps: {
        style:
            {
                color: theme.palette.primary[0],
                borderColor: theme.palette.primary[1]
            }
    }
}

const basicStyle = {
    rowStyle,
    colStyle,
    gutter,
    tooltipStyle,
    popconfirmButtonsProps
};

export default basicStyle;
