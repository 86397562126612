import jwtDecode from 'jwt-decode';
import SuperFetch from './superFetch';

class AuthHelper {
    login = async userInfo => {
        if (!userInfo.username || !userInfo.password) {
            return { error: 'please fill in the input' };
        }
        return await SuperFetch.post('login/', userInfo).then(response => {
            if (response.error) {
                return response
            }
            return this.checkExpirity(response.token)
        });
    };

    checkExpirity = token => {
        if (!token) {
            return {
                error: 'not matched',
            };
        }
        try {
            const profile = jwtDecode(token);

            const expiredAt = profile.expiredAt || profile.exp * 1000;

            if (expiredAt > new Date().getTime()) {

                return {
                    ...profile,
                    token,
                    expiredAt: new Date(expiredAt),
                };
            } else {
                return { error: 'Token expired' };
            }
        } catch (e) {
            console.log(e);

            return { error: 'Server Error' };
        }
    };
}
export default new AuthHelper();
