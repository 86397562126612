import styled from 'styled-components';
import {palette} from 'styled-theme';

const TimeRangePickerWrapper = styled.div` {

    .ant-calendar-picker {
      width: 100% !important;
    }
    
    .ant-calendar-picker .ant-input {
      font-size: 12px
    }
    
    .ant-calendar-range-picker-separator {
      font-size: 17px;
      line-height: 14px;
      margin-left: -14%;
    }
    .ant-calendar-range-picker-input {
      height: 108%;
      text-align: left;
    }
    
    input[placeholder^="End"] {
      margin-left: 4%;
    }
    
    .ant-calendar-picker-icon > svg {
      margin-top: -1px;
    }
    
    .ant-input:hover, .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
      border-color: ${palette('primary', 0)};
    }
        
    }`;

export default TimeRangePickerWrapper;
