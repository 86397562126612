import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import actions from './actions';
import reportsActions from '../reports/actions'
import UserHelper from '../../../helpers/userHelper';
import {ReportPropertiesQueryParameters} from "../../../helpers/utility";
import message from "../../../components/uielements/message";
import MessageContent from "../../../containers/Feedback/Message/message.style";
import React from "react";

export function* userFetchRequest() {
    yield takeEvery(actions.FETCH_USER, function* () {
        try {
            const result = yield call(UserHelper.fetchUser);
            if (result) {
                yield put(
                    actions.userFetchSuccess(result)
                );
                const queryParameters = new ReportPropertiesQueryParameters();
                let requestedPid = queryParameters.getPid(localStorage.getItem('pid'));
                const defaultPid = result.pids[requestedPid] || result.pids[Object.keys(result.pids)[0]];

                yield all([
                    put(actions.docsFetch()),
                    put(reportsActions.reportFetch({pid: defaultPid})),
                    put(actions.reportsFetch()),
                    put(actions.usageNotificationsFetch()),
                    put(actions.integrationsFetch()),
                    put(actions.blockingPoliciesFetch())
                ]);

            } else {
                yield put(actions.userFetchSuccess());
            }
        } catch (error) {
            yield put(actions.userFetchError());
        }
    });
}

export function* notificationsFetchRequest() {
    yield takeEvery(actions.FETCH_NOTIFICATIONS, function* () {
        try {
            const notifications = yield call(UserHelper.fetchNotifications);
            if (notifications) {
                yield put(
                    actions.notificationsFetchSuccess(notifications)
                );
            } else {
                yield put(actions.notificationsFetchSuccess());
            }
        } catch (error) {
            yield put(actions.notificationsFetchError());
        }
    });
}

export function* docsFetchRequest() {
    yield takeEvery(actions.FETCH_DOCS, function* () {
        try {
            const docs = yield call(UserHelper.fetchDocs);
            if (docs) {
                yield put(
                    actions.docsFetchSuccess(docs)
                );
            } else {
                yield put(actions.docsFetchSuccess());
            }
        } catch (error) {
            yield put(actions.docsFetchError());
        }
    });
}

export function* reportsFetchRequest() {
    yield takeEvery(actions.FETCH_REPORTS, function* (payload) {
        try {
            const reports = yield call(UserHelper.fetchReports);
            if (reports) {
                let actionsToRun = [put(actions.reportsFetchSuccess(reports))]
                if (payload.reportId) {
                    const reportProperties = reports.reports.find(report => report.id === payload.reportId)
                    if (reportProperties) {
                        actionsToRun.push(put(reportsActions.savedReportFetch(reportProperties)))
                    }
                }
                yield all(actionsToRun);
            } else {
                yield put(actions.reportsFetchSuccess());
            }
        } catch (error) {
            yield put(actions.reportsFetchError());
        }
    });
}

export function* usageNotificationsFetchRequest() {
    yield takeEvery(actions.FETCH_USAGE_NOTIFICATIONS, function* () {
        try {
            const usageNotification = yield call(UserHelper.fetchUsageNotifications);
            if (usageNotification) {
                yield put(
                    actions.usageNotificationsFetchSuccess(usageNotification)
                );
            } else {
                yield put(actions.usageNotificationsFetchSuccess());
            }
        } catch (error) {
            yield put(actions.usageNotificationsFetchError());
        }
    });
}

export function* usageNotificationSwitchRequest() {
    yield takeEvery(actions.SWITCH_USAGE_NOTIFICATION, function* (
        payload) {
        try {
            const response = yield call(
                UserHelper.switchUsageNotification, payload.usageNotification);
            if (response && !response.error) {
                yield all([
                    put(actions.switchUsageNotificationSuccess()),
                    put(actions.usageNotificationsFetch())
                ]);
            } else {
                message.error(<MessageContent>There seems to be an issue. Please try again in a few minutes or
                    contact your account manager</MessageContent>, 20);
                yield put(actions.switchUsageNotificationError());
            }
        } catch (error) {
            message.error(<MessageContent>There seems to be an issue. Please try again in a few minutes or
                contact your account manager</MessageContent>, 20);
            yield put(actions.switchUsageNotificationError());
        }
    });
}

export function* usersFetchRequest() {
    yield takeEvery(actions.FETCH_USERS, function* () {
        try {
            const response = yield call(UserHelper.fetchUsers);
            if (response.users) {
                yield put(
                    actions.usersFetchSuccess(response)
                );
            } else {
                yield put(actions.usersFetchSuccess());
            }
        } catch (error) {
            yield put(actions.usersFetchError());
        }
    });
}

export function* userUpdateRequest() {
    yield takeEvery(actions.USER_UPDATE, function* (
        payload) {
        try {
            const response = yield call(
                UserHelper.updateUser, payload.userProperties);
            if (response) {
                yield all([
                    put(actions.userUpdateSuccess()),
                    put(actions.usersFetch()),
                ]);
            } else {
                message.error(<MessageContent>There seems to be an issue. Please try again in a few minutes or
                    contact your account manager</MessageContent>, 20);
                yield put(actions.userUpdateError());
            }
        } catch (error) {
            message.error(<MessageContent>There seems to be an issue. Please try again in a few minutes or
                contact your account manager</MessageContent>, 20);
            yield put(actions.userUpdateError());
        }
    });
}

export function* integrationsFetchRequest() {
    yield takeEvery(actions.FETCH_INTEGRATIONS, function* () {
        try {
            const integrations = yield call(UserHelper.fetchIntegrations);
            if (integrations) {
                yield put(
                    actions.integrationsFetchSuccess(integrations)
                );
            } else {
                yield put(actions.integrationsFetchError());
            }
        } catch (error) {
            yield put(actions.integrationsFetchError());
        }
    });
}

export function* integrationUpdateRequest() {
    yield takeEvery(actions.UPDATE_INTEGRATION, function* (
        payload) {
        try {
            const response = yield call(
                UserHelper.updateIntegration, payload.integrationProperties);
            if (response && !response.error) {
                message.success(<MessageContent>Integration saved successfully</MessageContent>, 20);

                yield all([
                    put(actions.integrationUpdateSuccess()),
                    put(actions.integrationsFetch()),
                ]);
            } else {
                message.error(<MessageContent>There seems to be an issue. Please try again in a few minutes or
                    contact your account manager</MessageContent>, 20);
                yield put(actions.integrationUpdateError());
            }
        } catch (error) {
            message.error(<MessageContent>There seems to be an issue. Please try again in a few minutes or
                contact your account manager</MessageContent>, 20);
            console.log(error);
            yield put(actions.integrationUpdateError());
        }
    });
}

export function* integrationRemoveRequest() {
    yield takeEvery(actions.REMOVE_INTEGRATION, function* (
        payload) {
        try {
            const response = yield call(
                UserHelper.removeIntegration, payload.integrationProperties);
            if (response && !response.error) {
                message.success(<MessageContent>Integration removed successfully</MessageContent>, 20);
                yield all([
                    put(actions.removeIntegrationSuccess()),
                    put(actions.integrationsFetch())
                ]);
            } else {
                message.error(<MessageContent>There seems to be an issue. Please try again in a few minutes or
                    contact your account manager</MessageContent>, 20);
                yield put(actions.removeIntegrationError());
            }
        } catch (error) {
            message.error(<MessageContent>There seems to be an issue. Please try again in a few minutes or
                contact your account manager</MessageContent>, 20);
            yield put(actions.removeIntegrationError());
        }
    });
}

export function* blockingPoliciesFetchRequest() {
    yield takeEvery(actions.FETCH_BLOCKING_POLICIES, function* () {
        try {
            const blockingPolicies = yield call(UserHelper.fetchBlockingPolicies);
            if (blockingPolicies) {
                yield put(
                    actions.blockingPoliciesFetchSuccess(blockingPolicies)
                );
            } else {
                yield put(actions.blockingPoliciesFetchError());
            }
        } catch (error) {
            yield put(actions.blockingPoliciesFetchError(error));
        }
    });
}

export function* blockingPolicyUpdateRequest() {
    yield takeEvery(actions.UPDATE_BLOCKING_POLICY, function* (
        payload) {
        try {
            const response = yield call(
                payload.blockingListType ? UserHelper.updateBlockingList : UserHelper.updateBlockingPolicy,
                payload.blockingPolicyProperties, payload.blockingListType);
            if (response && !response.error) {
                message.success(<MessageContent>
                    {`Blocking ${payload.blockingListType ? 'List' : 'Policy'} Saved Successfully`}
                </MessageContent>, 20);

                yield all([
                    put(actions.blockingPolicyUpdateSuccess()),
                    put(actions.blockingPoliciesFetch()),
                ]);
            } else {
                message.error(response.message ? <MessageContent>{response.message}</MessageContent>
                    : <MessageContent>There seems to be an issue. Please try again in a few minutes or
                    contact your account manager</MessageContent>, 20);
                yield put(actions.blockingPolicyUpdateError());
            }
        } catch (error) {
            message.error(<MessageContent>There seems to be an issue. Please try again in a few minutes or
                contact your account manager</MessageContent>, 20);
            console.log(error);
            yield put(actions.blockingPolicyUpdateError());
        }
    });
}

export function* blockingPolicyRemoveRequest() {
    yield takeEvery(actions.REMOVE_BLOCKING_POLICY, function* (
        payload) {
        try {
            const response = yield call(
                payload.blockingListType ? UserHelper.removeBlockingList : UserHelper.removeBlockingPolicy,
                payload.blockingPolicyProperties, payload.blockingListType);
            if (response && !response.error) {
                message.success(<MessageContent>
                    {`Blocking ${payload.blockingListType ? 'List' : 'Policy'} Removed Successfully`}
                </MessageContent>, 20);
                yield all([
                    put(actions.removeBlockingPolicySuccess()),
                    put(actions.blockingPoliciesFetch())
                ]);
            } else {
                message.error(<MessageContent>There seems to be an issue. Please try again in a few minutes or
                    contact your account manager</MessageContent>, 20);
                yield put(actions.removeBlockingPolicyError());
            }
        } catch (error) {
            message.error(<MessageContent>There seems to be an issue. Please try again in a few minutes or
                contact your account manager</MessageContent>, 20);
            yield put(actions.removeBlockingPolicyError());
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(userFetchRequest),
        fork(notificationsFetchRequest),
        fork(docsFetchRequest),
        fork(reportsFetchRequest),
        fork(usageNotificationsFetchRequest),
        fork(usageNotificationSwitchRequest),
        fork(usersFetchRequest),
        fork(userUpdateRequest),
        fork(integrationsFetchRequest),
        fork(integrationUpdateRequest),
        fork(integrationRemoveRequest),
        fork(blockingPoliciesFetchRequest),
        fork(blockingPolicyUpdateRequest),
        fork(blockingPolicyRemoveRequest)
    ]);
}