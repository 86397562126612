import React, {Component} from "react";
import {InputNumber, Slider} from "antd";
import {formatLargeNumbers, formatPercentageForDataTable} from "../../../helpers/utility";
import MetricFilterWrapper from "./rangeMetricFilter.style";

export default class RangeMetricFilter extends Component {

    valueFormatter = (value) => {
        return this.props.isPercentage ? formatPercentageForDataTable(value) : formatLargeNumbers(value).toString();
    };

    sliderRangeInput = () => {
        let marks = {};
        marks[this.props.validRange[0]] = this.valueFormatter(this.props.validRange[0]);
        marks[this.props.validRange[1]] = this.valueFormatter(this.props.validRange[1]);

        return <div>
            <Slider range marks={marks}
                    tipFormatter={this.valueFormatter}
                    value={[this.props.min, this.props.max]}
                    min={this.props.validRange[0]}
                    max={this.props.validRange[1]}
                    onChange={(range) => this.props.handleChange(range[0], range[1])}
                    onAfterChange={(range) => this.props.submit(range[0], range[1])}
            />
        </div>
    };

    numbersRangeInput = () => {
        return <div>
            <InputNumber
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                value={this.props.min}
                onChange={(value) => this.props.onMinChange(value)}
                size="small"
                style={{marginRight: 6}}
            />
            -
            <InputNumber
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                value={this.props.max}
                size="small"
                onChange={(value) => this.props.onMaxChange(value)}
                style={{marginLeft: 6}}
            />
        </div>
    };

    render() {
        return <MetricFilterWrapper>
            {this.props.validRange ? this.sliderRangeInput() : this.numbersRangeInput()}
        </MetricFilterWrapper>
    }
}