const actions = {
    ACTIVATE_LANG_MODAL: 'ACTIVATE_LANG_MODAL',
    SWITCH_PINNING: 'SWITCH_PINNING',
    switchActivation: () => ({
        type: actions.ACTIVATE_LANG_MODAL
    }),
    switchSidebarPinning: () => ({
        type: actions.SWITCH_PINNING
    })
};
export default actions;
