import styled from 'styled-components';
import {palette} from 'styled-theme';

const ReportPropertiesWrapper = styled.div`   
  .ant-row > div.ant-form-item-label, .ant-row > div.ant-form-item-control-wrapper {
    padding-bottom: 8px!important;
    padding-top: 4px!important;
  };
  
  .ant-badge-dot {
    background: ${palette('primary', 0)}
  }
  
  .readOnly.ant-timeline {
    svg {
      width: 18px;
      height: 18px;
      fill: ${palette('secondary', 2)};    
    }
  }
  
  .horizontalReadOnly {
    span {
      color: ${palette('text', 1)}
    }
    
    svg {
        width: 13px;
        height: 13px;
        margin-right: 5px;
        fill: ${palette('text', 1)};    
    }
    
    b > u {
      cursor: default;
      color: ${palette('secondary', 2)};
    }
  }
  
  .horizontalReadOnly.inTooltip {
        color: ${palette('grayscale', 11)};
        b {
          color: #ffffff;          
        }
        span {
          color: ${palette('grayscale', 11)};
          b {
            color: #ffffff;          
          }
        }
        i svg {
            fill: #ffffff;   
        }
  }
  
  .ant-calendar-picker-icon > svg, .certain-category-icon > svg{
    width: 18px;
    height: 18px;
    fill: ${palette('grayscale', 0)};
  }
  
  .filter-icon > svg {
    fill: ${palette('grayscale', 7)};
    width: 24px;
    height: 24px;  
  }
  
  .ant-badge-status-text, .ant-form-item-label > label {
    color: ${palette('text', 0)};
  }
  
  .ant-select .ant-select-selection--multiple li.ant-select-selection__choice, div.ant-tag {
    background-color: ${palette('primary', 6)};
    color: ${palette('primary', 0)};
  }

  .ant-select-selection.ant-select-selection--multiple {
     &:focus,
     &:hover,
     &:active {
       border-color: ${palette('primary', 0)};
     }
  }
  
  .ant-select-selection--multiple .ant-select-selection__choice__remove svg, div.ant-tag svg {
    fill: ${palette('primary', 0)};  
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header[role="button"] {
    color: inherit;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    background: transparent;
  }

  .ant-collapse > .ant-collapse-item {
    border-bottom-color: ${palette('border', 0)};
  }
  
  .reportFormIcon > i, .ant-badge > i {
    vertical-align: text-top;
    svg {
      width: 16px;
      height: 16px;
      path:nth-child(2), polygon {
        fill: grey
      }
    }
  }
  
  .reportFormPicker {
    margin-bottom: 0;
    padding-bottom: 0;
    .ant-form-item-label {
      width: fit-content;
    }
  }
`;

export default ReportPropertiesWrapper;