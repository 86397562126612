import React, {Component} from 'react';
import {connect} from 'react-redux';
import Radio, {RadioGroup} from '../../../components/uielements/radio';
import Form from '../../../components/uielements/form';
import basicStyle from '../../../settings/basicStyle';
import Input, {Textarea} from '../../../components/uielements/input';
import OpenSupportTicketWrapper from './openSupportTicket.style';
import ContentHolder from '../../../components/utility/contentHolder';
import {Col, Row, Steps, Typography} from 'antd';
import Button from '../../../components/uielements/button';
import theme from '../../../settings/themes/protectedmediatheme';
import ReadOnlyReportProperties from './reportProperties/readOnlyReportProperties';
import {validateEmail} from '../../../helpers/utility';
import ReportsActions from '../../redux/reports/actions';

const {openSupportTicket} = ReportsActions;
const {Paragraph} = Typography;
const FormItem = Form.Item;
const {Step} = Steps;

const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px'
};

const ISSUE_TYPES = [
    {
        title: 'Dashboard Feedback',
        description: "you've had a stroke of inspiration! Got a dream dashboard feature in mind? " +
            "Share it and we could make it come true"
    },
    {title: 'Detection Inquiry', description: "Wondering why a certain traffic source has/has not been flagged? drop us a line"},
    {
        title: 'Unpredictable Dashboard Behavior',
        description: 'we track errors automatically, but if an issue persists, get in touch'
    },
    {
        title: 'Solution Trial Request',
        description: 'You are interested to check out one or more of our solutions (please write the solution you are interested in below)'
    },
    {
        title: 'Other...',
        hasFreeTextDescription: true
    }
];

class OpenSupportTicketForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentStep: 0,
            selectedIssueType: props.solutionTrialMode ? 3 : 0,
            freeTextIssueType: '',
            freeTextIssueTypeError: false,
            issueDescription: props.solutionTrialMode || '' ,
            issueDescriptionError: false,
            contactName: props.userName,
            contactNameError: false,
            contactEmail: props.userEmail,
            contactEmailError: false
        };

        this.getRadioFromIssueType = this.getRadioFromIssueType.bind(this);
    };

    onIssueTypeChange = (newIssueType) => {
        this.setState({selectedIssueType: newIssueType.target.value})
    };

    onFreeTextIssueTypeChange = (newFreeTextIssueType) => {
        let newState = {freeTextIssueType: newFreeTextIssueType.target.value};
        if (this.state.freeTextIssueType && newFreeTextIssueType.target.value.length > 3) {
            newState.freeTextIssueTypeError = false
        }
        this.setState(newState)

    };

    onIssueDescriptionChange = (newIssueDescription) => {
        let newState = {issueDescription: newIssueDescription.target.value};
        if (this.state.issueDescriptionError && newIssueDescription.target.value.length > 3) {
            newState.issueDescriptionError = false
        }
        this.setState(newState)
    };

    onContactNameChange = (newContactName) => {
        this.setState({
            contactName: newContactName.target.value,
            contactNameError: newContactName.target.value.length < 2
        })
    };

    onContactEmailChange = (newContactEmail) => {
        this.setState({
            contactEmail: newContactEmail.target.value,
            contactEmailError: !validateEmail(newContactEmail.target.value)
        })
    };

    getRadioFromIssueType = (issueType, index) => (
        <Radio style={radioStyle} key={index} value={index}>
            <b>{issueType.title}</b> {!issueType.hasFreeTextDescription ? issueType.description : ''}
            {this.state.selectedIssueType === index && issueType.hasFreeTextDescription ? (
                <FormItem hasFeedback={this.state.freeTextIssueTypeError}
                          style={issueType.hasFreeTextDescription ? {marginBottom: 0} : null}
                          validateStatus={this.state.freeTextIssueTypeError ? 'error' : 'success'}
                          help={this.state.freeTextIssueTypeError ? 'Please insert the type of the issue' : ''}>
                    <Input onChange={this.onFreeTextIssueTypeChange}
                           value={this.state.freeTextIssueType}
                           style={{width: '100%', marginLeft: 10}}/>
                </FormItem>
            ) : null}
        </Radio>
    );

    generateSteps = () => ([
        {
            title: 'Issue Details',
            content: [
                <FormItem key='type' label='What are you reporting?' style={{marginBottom: 0}}>
                    <RadioGroup onChange={this.onIssueTypeChange}
                                value={this.state.selectedIssueType}>
                        {ISSUE_TYPES.map(this.getRadioFromIssueType)}
                    </RadioGroup>
                </FormItem>,

                <FormItem key='description' label='Please describe how we can help you'
                          hasFeedback={this.state.issueDescriptionError}
                          validateStatus={this.state.issueDescriptionError ? 'error' : 'success'}
                          help={this.state.issueDescriptionError ? 'Please insert a short description of the issue' : ''}>
                    <p style={{color: theme.palette.secondary[4], fontSize: 13}}>
                        For your convenience,
                        you can close this feedback window to take a second look at the data at any point.
                        The text you already typed will be still here when you'll back.
                    </p>
                    <Textarea onChange={this.onIssueDescriptionChange}
                              value={this.state.issueDescription}
                              rows={4}/>
                </FormItem>]
        },
        {
            title: 'Report Properties',
            content: [<ReadOnlyReportProperties
                key='reportProperties'
                reportProperties={this.props.reportProperties}
                vertical/>]
        },
        {
            title: 'Review and Submit',
            content: [
                <Paragraph key='issueTypeReview'><b>{ISSUE_TYPES[this.state.selectedIssueType].title}- </b>
                    {ISSUE_TYPES[this.state.selectedIssueType].description || this.state.freeTextIssueType}
                </Paragraph>,
                <ReadOnlyReportProperties
                    key='reportProperties'
                    reportProperties={this.props.reportProperties}
                    vertical/>,
                <FormItem key='name' label='Contact name'
                          hasFeedback={this.state.contactNameError}
                          validateStatus={this.state.contactNameError ? 'error' : 'success'}
                          help={this.state.contactNameError ? 'Please insert your name' : ''}>
                    <Input onChange={this.onContactNameChange}
                           value={this.state.contactName}/>
                </FormItem>,

                <FormItem key='email' label='Contact e-mail address'
                          hasFeedback={this.state.contactEmailError}
                          validateStatus={this.state.contactEmailError ? 'error' : 'success'}
                          help={this.state.contactEmailError ? 'Please insert a valid email address' : ''}>
                    <Input onChange={this.onContactEmailChange}
                           value={this.state.contactEmail}/>
                </FormItem>
            ]
        },
    ]);

    onNextStepClick = () => {
        if (this.state.currentStep === 0 && this.state.issueDescription.length <= 3) {
                this.setState({issueDescriptionError: true})
        } else if (ISSUE_TYPES[this.state.selectedIssueType].hasFreeTextDescription
            && this.state.freeTextIssueType.length <= 3) {
            this.setState({freeTextIssueTypeError: true})
        } else {
            this.setState({currentStep: this.state.currentStep + 1})
        }
    };

    onBackStepClick = () => {
        this.setState({currentStep: this.state.currentStep - 1})
    };

    submitIssue = () => {
        const {openSupportTicket, onSubmit} = this.props;
        const issueType = ISSUE_TYPES[this.state.selectedIssueType];
        openSupportTicket({
            issueDetails: {
                issueType: issueType.title,
                issueTypeDescription: issueType.description || `(${this.state.freeTextIssueType})`,
                issueDescription: this.state.issueDescription
            },
            reportProperties: this.props.reportProperties,
            contactInfo: {
                name: this.state.contactName,
                email: this.state.contactEmail
            },
            onSubmit: onSubmit
        });
    };

    render() {
        const {rowStyle, gutter} = basicStyle;
        const steps = this.generateSteps();
        return (
            <OpenSupportTicketWrapper>
                {this.state.currentStep === 0 ?
                    <Paragraph style={{fontSize: 13}}>
                        Is there something in the data that you'd like us to clarify?<br/>
                        Use the form below or email your Customer Success rep and we'll be happy to explain the
                        results in more detail. <br/>
                        You can close this feedback window at any time, in order to take a second look at the dashboard,
                        and we'll save your comments until you're ready to submit them.<br/>
                        <i style={{fontSize: 11}}>Please note that you may occasionally see an unexpected spike in
                            fraudulent traffic when we release a new detection logic.<br/>
                            Please also keep in mind that you will inevitably find discrepancies between the
                            reports from different verification vendors.</i>
                    </Paragraph> : null}

                <Steps current={this.state.currentStep}
                       labelPlacement='vertical'>
                    {steps.map(item => (
                        <Step key={item.title} title={item.title}/>
                    ))}
                </Steps>
                <Form>
                    <ContentHolder>
                        {steps[this.state.currentStep].content}
                    </ContentHolder>
                    <Row style={rowStyle} gutter={gutter} justify='start' id='dashboard-table'>
                        <Col lg={22}>
                            {this.state.currentStep > 0 ?
                                <Button type='primary' onClick={this.onBackStepClick}>
                                    Back
                                </Button> : null}
                        </Col>
                        <Col lg={2}>
                            {this.state.currentStep < steps.length - 1 ?
                                <Button type='primary' onClick={this.onNextStepClick}>Next</Button> :
                                <Button type='primary'
                                        onClick={this.submitIssue}
                                        loading={this.props.isOpenSupportTicketLoading}>Send</Button>}
                        </Col>
                    </Row>
                </Form>
            </OpenSupportTicketWrapper>
        );
    }
}

function mapStateToProps(state) {
    return {
        isFiltersSidebarActivated: state.FiltersSidebar.isActivated,
        isDesktop: state.App.view === 'DesktopView',
        isLoading: state.ReportsFetch.loading || state.UsersFetch.loading,
        userName: state.UsersFetch.name,
        userEmail: state.UsersFetch.username,
        reportProperties: state.ReportsFetch.reportProperties,
        isOpenSupportTicketLoading: state.ReportsFetch.isOpenSupportTicketLoading,
        isUserMaster: state.UsersFetch.isMaster
    }
}

export default connect(
    mapStateToProps,
    {openSupportTicket},
    null
)(OpenSupportTicketForm);