import styled from 'styled-components';
import {palette} from 'styled-theme';


const OpenSupportTicketWrapper = styled.div`
  .ant-steps-item-title {
    font-size: 14px;
    line-height: 18px;
  }
  
  .ant-steps-item-process .ant-steps-item-icon {
    background: ${palette('primary', 0)};
    border-color: ${palette('primary', 1)};
  }
  
  .ant-steps-item-finish > .ant-steps-item-tail::after {
    background: ${palette('primary', 0)};
  }
  
  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: ${palette('primary', 1)};
  }
  
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: ${palette('primary', 1)};
  }
  
  .ant-timeline-item-last {
    padding-bottom: 0;
      
    .ant-timeline-item-content {
      min-height: 0;    
    }
  }
`;

export default OpenSupportTicketWrapper;
