export const USER_CONFIGURATIONS_RESPONSE_MOCK = {};

export const NOTIFICATIONS_RESPONSE_MOCK = [];

export const REPORTS_RESPONSE_MOCK = [];

export const DOCS_RESPONSE_MOCK = [];

export const USAGE_NOTIFICATIONS_RESPONSE_MOCK = [];

export const USERS_RESPONSE_MOCK = {};

export const INTEGRATIONS_RESPONSE_MOCK = {};

export const BLOCKING_POLICIES_RESPONSE_MOCK = {}