import {all} from 'redux-saga/effects';
import reportsFetchSagas from './reports/sagas'
import usersFetchSagas from './users/sagas'


export default function* devSaga() {
    yield all([
        reportsFetchSagas(), usersFetchSagas()
    ]);
}
