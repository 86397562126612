import styled from 'styled-components';
import {palette} from 'styled-theme';

const MetricFilterWrapper = styled.div`   
  .ant-slider-mark {
    font-size: 10px;
  }
  
  .ant-input-number {
    margin-bottom: 8px;
  }
  
  .ant-slider-track {
    background-color: ${palette('primary', 7)};
  }
  
  .ant-slider:hover .ant-slider-track {
    background-color: ${palette('primary', 0)};
  }
  .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open), .ant-slider-handle.ant-tooltip-open {
    border: solid 2px ${palette('primary', 0)}; 
  }
  
  .ant-slider-handle {
      border: solid 2px ${palette('primary', 7)}; 
      &:focus {
        border-color: ${palette('primary', 7)};
        box-shadow: 0 0 0 5px rgba(144, 155, 255, 0.2)
      }
  }

  .ant-slider:hover .ant-slider-rail {
    background-color: ${palette('primary', 6)};
    &:hover {
      background-color: ${palette('primary', 5)};
    }
  }
`;

export default MetricFilterWrapper;