import styled from 'styled-components';
import {font, palette} from 'styled-theme';

const DashAppHolder = styled.div`
  font-family: ${font('primary', 0)};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  input,
  textarea,
  span,
  div,
  img,
  svg {
    &::selection {
      background: ${palette('primary', 0)};
      color: #fff;
    }
  }

  .ant-row:not(.ant-form-item) {${'' /* margin-left: -8px;
    margin-right: -8px; */};

    &:before,
    &:after {
      display: none;
    }
  }

  .ant-row > div {
    padding: 0;
  }

  .isoLeftRightComponent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .isoCenterComponent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .onPrintOnly {
    display: none;
  }

  @media print {
    @page {
      size: landscape;
    }

    div, section {
      overflow: visible !important;
    }

    .ant-layout {
      background: white;
    }

    header {
      position: absolute !important;
      height: 60px;
    }

    footer {
      height: 50px;
      padding: 0;
      display: table;
    }

    .overviewSection {
      display: table;
      width: 100%;
      padding-right: 270px !important;
    }

    .left-side-chart {
      width: 30%;
      display: inline-block !important;
    }

    .right-side-chart {
      width: 50%;
      display: inline-block !important;
    }

    .isoBoxWrapper, .ant-tabs.ant-tabs-top, .dashboardTemplate {
      padding: 0;
      border: none;
      display: block;
    }

    .isomorphicTopbar {
      padding: 0 !important;
    }

    .isomorphicSidebar, .isomorphicTopbar .isoLeft, .isomorphicTopbar .isoRight, .isoBoxTitle, .insights,
    .isoThemeSwitcher, .rightReportHeader, .tableManagementSection, .ant-tabs-nav-wrap, .ant-tabs-bar, .darkBtn,
    .ant-pagination-prev, .ant-pagination-next, .ant-pagination-item, .ant-pagination-options-size-changer,
    .expandableHeader, footer p, .ant-table-column-sorter, .ant-table-thead > tr > th i{
      display: none !important;
    }
    
    .slick-track > :not(.slick-active) {
      opacity: 0;
    }

    .ant-pagination {
      margin: 0 !important;
      float: none !important;
    }

    .dashboardTemplateLayout {
      padding-right: 0 !important;
    }

    .onPrintOnly {
      display: block;
    }

    #dashboard-table table {
      -webkit-print-color-adjust: exact;

      .ant-table-header-column {
        display: block
      }
    }

  }

`;

export default DashAppHolder;
