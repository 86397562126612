import Auth0Lock from 'auth0-lock';
import history from './history';
import { Auth0Config } from '../../settings';
import { notification } from '../../components';

class Auth0Helper {
  isValid = Auth0Config.clientID && Auth0Config.domain;

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
  }
  login(handleLogin) {
    this.lock = this.isValid
      ? new Auth0Lock(
          Auth0Config.clientID,
          Auth0Config.domain,
          Auth0Config.options
        )
      : null;
    if (!this.lock) {
      return;
    }
    this.lock.on('authenticated', authResult => {
      if (authResult && authResult.accessToken) {
        if (window) {
          localStorage.setItem('id_token', authResult.accessToken);
        }
        handleLogin();
      } else {
        notification('error', 'Wrong mail or password');
      }
    });
    this.lock.show();
  }
  handleAuthentication() {
    localStorage.setItem('id_token', 'secret token');
    history.replace('/dashboard');
  }

  logout() {
    // Clear ID token from local storage
    localStorage.removeItem('id_token');
    // navigate to the home route
    history.replace('/');
  }
}
export default new Auth0Helper();
