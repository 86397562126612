import styled from 'styled-components';
import {palette} from 'styled-theme';
import SidebarWrapper from '../../../containers/Sidebar/sidebar.style'

const DashboardSidebarWrapper = styled(SidebarWrapper)`
    &&& {
        .isoLogoWrapper {
          background: rgba(0, 0, 0, 0);
            
          img {
            max-width: 90%;
            max-height: 90%;
          }
        }
        
        svg {
          height: 16px;
          width: 16px;
        }

        .isoDashboardMenu {
          .ant-menu-item-selected {
            background-color: #434871 !important;  
            border-bottom-left-radius: 21px;  
            border-top-left-radius: 21px;  
            
            .anticon {
              fill: #6772e4;
            }
    
            i {
              fill: #6772e4;
            }
    
            .nav-text {
              color: ${palette('secondary', 2)};
            }
          }
          
          i, .nav-text {
              fill: ${palette('secondary', 2)};
              color: ${palette('secondary', 2)};
          }

          > li {
            &:hover {
              i,
              .nav-text {
                color: #c2c6eb;
                fill: #c2c6eb
              }
            }
          }
        }

        .ant-menu-submenu-inline,
        .ant-menu-submenu-vertical {
          > .ant-menu-submenu-title {
            &:hover {
              .ant-menu-submenu-arrow {
                &:before,
                &:after {
                  color: #c2c6eb;
                }
              }
            }
          }
          
          .ant-menu-inline,
          .ant-menu-submenu-vertical {
            > li:not(.ant-menu-item-group) {
              background-color: #3b3f64;
              color: #777ba0;
              border-bottom-left-radius: 0;  
              border-top-left-radius: 0;
              a {
                color: #777ba0;
              }
              
              a, span {
                width: 100%;
                height: 100%;
              }
              
              .title {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  width: 85%;
                  
                  i {
                    margin-right: 8px;
                    svg {
                      fill: ${palette('secondary', 2)};
                    }
                  }
              }
              
              .badge {
                width: 15%;
                height: 100%;
              }
              
              div {
                display: inline-block;             
                .ant-badge i {
                    margin: 0;
                    border-radius: 16px;
                    height: 18px;
                    width: 18px;
                    position: relative;
                    right: 6px;
                    & svg {
                      height: 13px;
                      width: 14px;
                      fill: ${palette('secondary', 2)};
                      margin-top: 2px;
                    }
                }
              }
              
              &:not(.ant-menu-item-disabled):hover {
                color: #c2c6eb!important;
                a, span {
                  color: #c2c6eb!important;
                }
              }
            }
          }
        }
        
        .solutionsMenu:hover {
          > li {
          background-color: inherit;
          color: #c2c6eb
          }
        }
  }
`;

export default DashboardSidebarWrapper;
