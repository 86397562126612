import {
    BrandProtectionSVG,
    InfoSVG,
    MaterialRoundedCode,
    MaterialRoundedUsage,
    MaterialRoundedUsers,
    OverviewSVG
} from "./components/icons/icons";

const options = [
    {
        key: 'dashboard',
        label: 'sidebar.overview',
        leftIcon: OverviewSVG
    },
    {
        key: 'docs',
        label: 'sidebar.docs',
        leftIcon: InfoSVG
    },
    {
        key: 'usage_notifications',
        label: 'sidebar.usageNotification',
        leftIcon: MaterialRoundedUsage
    },
    {
        key: 'blocking_policy',
        label: 'sidebar.blockingPolicy',
        leftIcon: BrandProtectionSVG,
        onlyForBlockingPolicyPermissions: true
    },
    {
        key: 'integration',
        label: 'sidebar.integration',
        leftIcon: MaterialRoundedCode
    },
    {
        key: 'users',
        label: 'sidebar.users',
        leftIcon: MaterialRoundedUsers
    }
];
export default options;
