import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {push} from 'react-router-redux';
import actions from './actions';
import {clearToken, getToken, setToken} from '../../helpers/utility';
import AuthHelper from '../../helpers/authHelper';
import notification from '../../components/notification';

export function* loginRequest() {
    yield takeEvery('LOGIN_REQUEST', function* (payload) {
        const { history, userInfo, loginCallback} = payload.payload;
        const result = yield call(AuthHelper.login, userInfo);
        if (loginCallback) {loginCallback()}
        if (result.token) {
            yield put({
                type: actions.LOGIN_SUCCESS,
                payload: result,
                token: result.token,
                history
            });
        } else {
            notification('error',
                result.error === 'too many attempts' ?
                    "This account has been locked because incorrect password was entered. Please try again in 10 minutes. " +
                    "If you forgot your password, contact our Customer Success team." :
                "Oops, Enter the correct username and password to sign in");
            yield put({type: actions.LOGIN_ERROR});
        }
    });
}

export function* loginSuccess() {
    yield takeEvery(actions.LOGIN_SUCCESS, function*({ payload, history }) {

        yield setToken(payload.token);
        if (history) {
            // Check for the last query url and redirect user to it if it exists
            const queryUrlPart = window.location.hash;
            history.push(`/dashboard${queryUrlPart}`);
        }
    });
}

export function* loginError() {
    yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

export function* logout() {
    yield takeEvery(actions.LOGOUT, function*() {
        clearToken();
        yield put(push('/'));
    });
}
export function* checkAuthorization() {
    yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
        const { token } = AuthHelper.checkExpirity(getToken());
        if (token) {
            yield put({
                type: actions.LOGIN_SUCCESS,
                payload: { token },
                token,
                profile: 'Profile'
            });
        }
    });
}
export default function* rootSaga() {
    yield all([
        fork(checkAuthorization),
        fork(loginRequest),
        fork(loginSuccess),
        fork(loginError),
        fork(logout)
    ]);
}
