import styled from 'styled-components';
import {palette} from 'styled-theme';

const FilterInputWrapper = styled.div`
    .ant-input-group {
      width: 100%;
    }

    button.filter-creation-button {
      width: 10%;
      padding: 0;
      font-size: 12px;
      font-weight: 900;
      height: 35px;
    }         
    
    .ant-select-dropdown-menu-item-group > .ant-select-dropdown-menu-item-group-list {
     .ant-select-dropdown-menu-item-group {
      padding-left: 6px;
     }
    } 
    
    
    .ant-input-number, .ant-cascader-input {
       color: ${palette('text', 1)};
       border: 1px solid ${palette('border', 0)};
       background: #fff;
       
       &:hover {
         border-color: ${palette('primary', 0)};
       }

    }
    
    .ant-cascader-picker:focus .ant-cascader-input, .ant-input:focus, .ant-input-number:focus {
      background: #fff;
      border-color: ${palette('primary', 0)};
      box-shadow: 0 0 0 2px ${palette('primary', 3)};
    }
    
    .ant-input.ant-cascader-input {
      height: 35px;
    }
    
    .ant-cascader-picker-label {
      font-size: 13px;
    }
        
    //Radio Buttons Wrappers
    #percentage-change {
        .ant-radio-button-wrapper {
            color: ${palette('text', 1)};
            border: 1px solid ${palette('border', 0)};
            background: #fff;
            padding: 0 8px;
            
            &:hover,
            &.ant-radio-button-wrapper-focused {
              color: ${palette('primary', 0)};
            }
        
            &.ant-radio-button-wrapper-checked {
              background: #fff;
              border-color: ${palette('primary', 0)};
              color: ${palette('primary', 0)};
              box-shadow: -1px 0 0 0 ${palette('primary', 0)};
            }
        }
    }    
`;
export default FilterInputWrapper;