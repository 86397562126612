import React, {Component} from "react";
import {Typography} from 'antd';
import {SOLUTIONS} from "../../config";
import theme from "../../../settings/themes/protectedmediatheme";
import Logo from "../../../components/utility/logo";

const {Title, Paragraph, Text} = Typography;

const STYLE = {
    paddingTop: 100
};

const TITLE_STYLE = {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.secondary[12]
};

const SOLUTIONS_TO_IGNORE_METRICS = ['suspicious'];
const SHOW_METRIC_GROUP_TITLE_WITH_METRIC = ['visibility', 'time-on-site'];

export default class PrintExtension extends Component {

    metricData = (metric, metricGroupKey, metricGroupTitle) => {
        if (metric.description) {
            return <Paragraph key={`${metric.key}-${metric.title}`} style={{marginBottom: 6, fontSize: 13}}>
                <b> - {SHOW_METRIC_GROUP_TITLE_WITH_METRIC.indexOf(metricGroupKey) >= 0 ?
                    `${metricGroupTitle}- ` : ''}
                    {metric.title}-</b> {metric.description}
            </Paragraph>
        }
    };


    solutionParagraph = (solutionKey) => {
        const solution = SOLUTIONS[solutionKey];
        return <Paragraph key={solutionKey}>
            <Title level={4} style={{marginBottom: 0}}>{solution.title}</Title>
            <Text strong style={{fontSize: 13}}> {solution.description}</Text>
            {solution.metricsGroups
                .map(metricGroup => metricGroup.masterMetrics
                    .map(metric => this.metricData(metric, metricGroup.groupKey, metricGroup.title)))}
            {solution.metricsGroups
                .filter(metricGroup => SOLUTIONS_TO_IGNORE_METRICS.indexOf(metricGroup.groupKey) < 0)
                .map(metricGroup => metricGroup.metricsByCategories
                    .map(metric => this.metricData(metric)))}
        </Paragraph>
    };

    render() {
        return <Paragraph className='onPrintOnly' style={STYLE}>
            <Title level={3} style={TITLE_STYLE}>
                Solutions & Metrics
                <div className='onPrintOnly' style={{float: 'right'}}>
                    <Logo imgStyle={{maxHeight: 20}}/>
                </div>
            </Title>
            <Paragraph style={{columnCount: 3}}>
                {Object.keys(SOLUTIONS).filter(solution => !SOLUTIONS[solution].forMasterUsers).map(this.solutionParagraph)}
            </Paragraph>
        </Paragraph>
    }
}