import styled from 'styled-components';
import {palette} from 'styled-theme';

const SolutionsMenuWrapper = styled.div`

  .ant-switch {
    height: 12px
  }
  .ant-switch::after {
    background-color: #797d99;
    margin-left: 0;
    margin-top: -4px;
    height: 16px;
    width: 16px
  }
  .ant-switch-checked::after {
    background-color: ${palette('primary', 0)};
  }

  .ant-menu-dark .ant-menu-item-disabled {
    color: #666b8b !important;
  }
  
  &&& {
      .ant-menu-submenu-inline,
      .ant-menu-submenu-vertical {
        .ant-menu-inline,
        .ant-menu-submenu-vertical {
          > li:not(.ant-menu-item-group) {
              &:hover {
                background-color: inherit!important;
                a {
                  color: #c2c6eb!important;
                }
              }
          }
        }
      }
      
      .isoDashboardMenu > li:hover .nav-text {
        color: inherit;
      }
      
      .ant-menu-item, .ant-menu-submenu-title {
        cursor: auto;
      }
  }
  
  .enabledSolutionBadge {
    line-height: 14px;
    padding-right: 7px;
    
    sup {
      background: #c2c6eb;
      box-shadow: none;
    }
  }


`;

export default SolutionsMenuWrapper;
