import React, {Component} from 'react';
import {Icon} from 'antd';
import theme from "../../../settings/themes/protectedmediatheme";
import Modal from "../../../components/feedback/modal";
import {MaterialRoundedOpenSupportTicket} from "../../components/icons/icons";
import OpenSupportTicketForm from "../dashboard/openSupportTicket";
import {fireEventToGoogleAnalytics} from "../../../helpers/utility";
import {connect} from "react-redux";

class TopbarOpenSupportTicket extends Component {
    constructor(props) {
        super(props);
        this.handleVisibleChange = this.handleVisibleChange.bind(this);
        this.hide = this.hide.bind(this);
        this.state = {
            visible: false
        };
    }

    hide() {
        this.setState({visible: false});
    }

    handleVisibleChange =() => {
        this.setState({visible: !this.state.visible});
    };

    handleOpenSupportTicketClick() {
        fireEventToGoogleAnalytics('Share your feedback', 'Click');
        this.handleVisibleChange();
    }

    render() {
        return (
            <div className="isoIconWrapper">
                <Icon component={MaterialRoundedOpenSupportTicket}
                      style={{fill: theme.palette.grayscale[0]}} onClick={this.handleVisibleChange}/>
                <Modal
                    zIndex={1001}
                    visible={this.state.visible}
                    width={1000}
                    title="Share your feedback"
                    onCancel={this.handleVisibleChange}
                    footer={null}
                    key={this.props.reportId}>
                    <OpenSupportTicketForm onSubmit={this.handleVisibleChange}/>
                </Modal>
            </div>
        );
    }
}

export default connect(state => ({
    ...state.App,
    reportId: state.ReportsFetch.reportId
}))(TopbarOpenSupportTicket);
