import React, {Component} from 'react';
import {Icon, Popover} from 'antd';
import {connect} from 'react-redux';
import TopbarDropdownWrapper from '../../../containers/Topbar/topbarDropdown.style';
import theme from "../../../settings/themes/protectedmediatheme";
import Modals from "../../../components/feedback/modal";
import {ModalContent} from "../../../containers/Feedback/Modal/modal.style";

const BREAK_LINE = '\n'
const NOTIFICATION_ICONS = {
    info: <Icon type='info-circle' style={{color: theme.palette.primary[11], paddingRight: 4}}/>,
    success: <Icon type='check-circle' style={{color: theme.palette.success[0], paddingRight: 4}}/>,
    error: <Icon type='close-circle' style={{color: theme.palette.error[0], paddingRight: 4}}/>,
    warning: <Icon type='exclamation-circle' style={{color: theme.palette.warning[0], paddingRight: 4}}/>
};

class TopbarNotification extends Component {
    constructor(props) {
        super(props);
        this.handleVisibleChange = this.handleVisibleChange.bind(this);
        this.hide = this.hide.bind(this);
        this.state = {
            visible: false,
        };
    }

    hide() {
        this.setState({visible: false});
    }

    handleVisibleChange() {
        this.setState({visible: !this.state.visible});
    }

    onNotificationClick = (notification) => {
        const type = NOTIFICATION_ICONS.hasOwnProperty(notification.notification_type) ?
            notification.notification_type : 'info';
        const link = notification.redirect_to_url ?
            <a href='# ' onClick={() => window.open(notification.redirect_to_url)}> Click here to see more... </a> : '';
        Modals[type]({
            title: <h3>{notification.title}</h3>,
            content: (
                <ModalContent>
                    <p>
                        {notification.content.split(BREAK_LINE).map(
                            (line, index) => <p key={index}>{line}</p>)}
                    </p>
                    <p>
                        {link}
                    </p>
                </ModalContent>
            ),
            zIndex: 1001,
            onOk() {
            },
            okText: "OK",
            cancelText: "Cancel"
        })

    };

    render() {
        const content = (
            <TopbarDropdownWrapper className="topbarNotification">
                <div className="isoDropdownHeader">
                    <h3>
                        Notifications
                    </h3>
                </div>
                <div className="isoDropdownBody">
                    {this.props.notifications ? this.props.notifications.map(notification => (
                        <a href='# ' className="isoDropdownListItem" key={notification.id}
                           onClick={() => this.onNotificationClick(notification)}>
                            <h5>{NOTIFICATION_ICONS[
                                NOTIFICATION_ICONS.hasOwnProperty(notification.notification_type) ?
                                    notification.notification_type : 'info']}
                                {notification.title}</h5>
                            <p>{notification.content}</p>
                        </a>
                    )) : ''}
                </div>
            </TopbarDropdownWrapper>
        );
        const label = this.props.notifications && this.props.notifications.length ? <span>{this.props.notifications.length}</span> : '';
        return (
            <Popover
                content={content}
                trigger="click"
                visible={this.state.visible}
                onVisibleChange={this.handleVisibleChange}
                placement="bottomLeft"
            >
                <div className="isoIconWrapper">
                    <i
                        className="ion-android-notifications"
                        style={{color: theme.palette.grayscale[0]}}
                    />
                    {label}
                </div>
            </Popover>
        );
    }
}

export default connect(state => ({
    ...state.App,
    notifications: state.UsersFetch.notifications,
}))(TopbarNotification);
