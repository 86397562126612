import React from 'react';
import {Provider} from 'react-redux';
import {history, store} from './redux/store';
import PublicRoutes from './router';
import {ThemeProvider} from 'styled-components';
import {ConfigProvider as LocaleProvider} from 'antd';
import {IntlProvider} from 'react-intl';
import themes from './settings/themes';
import AppLocale from './languageProvider';
import config, {getCurrentLanguage} from './containers/LanguageSwitcher/config';
import {fullStoryConfig, themeConfig} from './settings';
import DashAppHolder from './dashAppStyle';
import Boot from './redux/boot';
import {initializeReactGA} from "./helpers/utility";
import FullStory from "react-fullstory";

const currentAppLocale =
    AppLocale[getCurrentLanguage(config.defaultLanguage || 'english').locale];

const thirdPartyTrackingComponent = () => {
    initializeReactGA();

    const components = []

    if (process.env.NODE_ENV === 'production') {
        components.push(<FullStory key='full-story' org={fullStoryConfig.orgId}/>)
    }
    return components
};

const DashApp = () => (
    <LocaleProvider locale={currentAppLocale.antd}>
        {thirdPartyTrackingComponent()}
        <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
        >
            <ThemeProvider theme={themes[themeConfig.theme]}>
                <DashAppHolder>
                    <Provider store={store}>
                        <PublicRoutes history={history}/>
                    </Provider>
                </DashAppHolder>
            </ThemeProvider>
        </IntlProvider>
    </LocaleProvider>
);
Boot()
  .then(() => DashApp())
  .catch(error => console.error(error));

export default DashApp;
export { AppLocale };
